import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  Switch,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddForm from "./Add"; // Adjust the path as necessary
import { deliveryAddress } from "../../Data/Data"; // Mock data for delivery addresses
import { API_URL } from "../../../hooks/Api";
import axios from "axios";
import fetchApi from "../../../hooks/ApiFetch";

const { Option } = Select;

const App = () => {
  axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null); // Track the order being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchEmail, setSearchEmail] = useState(""); // Search term for email
  const [searchMobile, setSearchMobile] = useState(""); // Search term for mobile number
  const [data, setData] = useState([]); // Data source for table
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApi.get(`/deliveryprofile`);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };
  // const [editingOrder, seteditingOrder] = useState(null); // Track the user being edited

  // Handle adding a new order
  const handleAddOrder = () => {
    setEditingOrder(null); // Reset for adding a new order
    setIsModalVisible(true);
  };

  // Handle editing an order
  const handleEditOrder = (order) => {
    setEditingOrder(order); // Set the order to be edited
    form.setFieldsValue(order); // Populate the form with order data
    setIsModalVisible(true);
  };

  // Handle deleting an order
  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      // content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/deliveryprofile/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Delivery Address Deleted",
            description: "Delivery Address has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the banner.",
          });
        }
      },
    });
  };

  // Handle modal cancellation
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handle form submission
  const handleOk = async (values) => {
    try {
      if (editingOrder) {
        // Update existing user
        await axios.put(
          `${API_URL}/deliveryprofile/${editingOrder._id}`,
          values
        );
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingOrder._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Delivery Address Updated",
          description: "Delivery Address has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await axios.post(`${API_URL}/deliveryprofile`, values);
        setData((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Delivery Address Added",
          description: "Delivery Address has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  // Handle page size change
  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };

  // Filter data based on search terms
  const filteredData = data.filter((item) => {
    return (
      item.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
      item.mobile_number.toLowerCase().includes(searchMobile.toLowerCase())
    );
  });

  // Table columns definition
  const columns = [
    {
      title: (
        <div>
          Email
          <Input
            placeholder="Search Email"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "email",
      key: "email",
      width: "20%",
    },
    {
      title: (
        <div>
          Mobile Number
          <Input
            placeholder="Search Mobile Number"
            value={searchMobile}
            onChange={(e) => setSearchMobile(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "Assigned",
      dataIndex: "assigned",
      key: "assigned",
      render: (text) => <Switch checked={text} disabled />,
    },
    {
      title: "Online",
      dataIndex: "is_online",
      key: "is_online",
      render: (text) => <Switch checked={text} disabled />,
    },
    {
      title: "Verified",
      dataIndex: "is_verified",
      key: "is_verified",
      render: (text) => <Switch checked={text} disabled />,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "latitude",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "longitude",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditOrder(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Delivery Addresses</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddOrder}
          >
            Add Address
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false} // Disable default pagination
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingOrder ? "Edit Address" : "Add Address"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddForm
          form={form}
          onFinish={handleOk}
          initialValues={editingOrder || {}}
        />
      </Modal>
    </div>
  );
};

export default App;
