import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Card, Row, Col, message, Select } from "antd";
import fetchApi from "../../../hooks/ApiFetch"; // Ensure this is correctly set up
import { DeleteOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/Context";
import ApiPath from "../../../hooks/ApiFetch";
import Spinner from "../../../components/spinner";
import axios from "axios";
// import MaterialIcons from "@expo/vector-icons";
// import MaterialIcons from "@expo/vector-icons/MaterialIcons";
const { Option } = Select;

const DynamicProductForm = ({ initialValues, form }) => {
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [loadingImage, setloadingImage] = useState(null);
  const { Parent, Category, Vendors } = useContext(AuthContext);
  const [categories, setCategories] = useState([
    {
      name: "",
      subcategories: [
        {
          subcategoryName: "",
          products: [{ product: "", price: 0, image: null }],
        },
      ],
    },
  ]);
  // console.log("initialValues", initialValues);
  useEffect(() => {
    console.log("initialValues", initialValues);

    if (!initialValues || !initialValues.name || !initialValues.categories) {
      setAdd(true);

      // Set default categories if initialValues are missing or incomplete
      setCategories([
        {
          name: "",
          subcategories: [
            {
              subcategoryName: "",
              products: [{ product: "", price: 0, image: null }],
            },
          ],
        },
      ]);
    } else {
      // Transform initialValues into the expected categories structure
      const updatedCategories = {
        name: initialValues.name,
        subcategories: Object.keys(initialValues.categories).map(
          (subcategoryName) => ({
            subcategoryName,
            products: initialValues.categories[subcategoryName].map(
              (product) => ({
                product: product.product,
                price: product.price,
                image: product.image,
              })
            ),
          })
        ),
      };

      // Set the transformed data
      setCategories([updatedCategories]);
    }
  }, [initialValues]);

  const handleAddCategory = () => {
    setCategories([
      ...categories,
      {
        name: "",
        subcategories: [
          {
            subcategoryName: "",
            products: [{ product: "", price: 0, image: null }],
          },
        ],
      },
    ]);
  };

  const handleCategoryChange = (index, field, value) => {
    const newCategories = [...categories];
    newCategories[index][field] = value;
    setCategories(newCategories);
  };

  const handleAddSubcategory = (categoryIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories.push({
      subcategoryName: "",
      products: [{ product: "", price: 0, image: null }],
    });
    setCategories(newCategories);
  };

  const handleSubcategoryChange = (categoryIndex, subIndex, field, value) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories[subIndex][field] = value;
    setCategories(newCategories);
  };

  const handleAddProduct = (categoryIndex, subIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories[subIndex].products.push({
      product: "",
      price: 0,
      image: null,
    });
    setCategories(newCategories);
  };

  const handleProductChange = (
    categoryIndex,
    subIndex,
    productIndex,
    field,
    value
  ) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories[subIndex].products[productIndex][
      field
    ] = value;
    setCategories(newCategories);
  };

  const handleDeleteProduct = (categoryIndex, subIndex, productIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories[subIndex].products.splice(
      productIndex,
      1
    );
    setCategories(newCategories);
  };

  const handleDeleteSubcategory = (categoryIndex, subIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories.splice(subIndex, 1);
    setCategories(newCategories);
  };

  const handleDeleteCategory = (categoryIndex) => {
    const newCategories = [...categories];
    newCategories.splice(categoryIndex, 1);
    setCategories(newCategories);
  };

  const handleDeleteAllProducts = (categoryIndex, subIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories[subIndex].products = [];
    setCategories(newCategories);
  };

  const handleDeleteAllProductsInCategory = (categoryIndex) => {
    const newCategories = [...categories];
    newCategories[categoryIndex].subcategories.forEach((subcategory) => {
      subcategory.products = [];
    });
    setCategories(newCategories);
  };
  const handleSubmit = async () => {
    setLoading(true);
    // Convert categories array to desired JSON structure
    const jsonResult = categories.map((category) => ({
      name: category.name,
      categories: category.subcategories.reduce((acc, subcat) => {
        acc[subcat.subcategoryName] = subcat.products.map((product) => ({
          product: product.product,
          price: product.price,
          image: product.image, // Include image data
          id:
            product.id ||
            Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000, // Retain existing ID or generate a new one
        }));
        return acc;
      }, {}),
    }));
    // console.log("jsonResult", jsonResult);
    try {
      let response;
      // If `initialValues` exist, perform an update (PUT request)
      if (!add) {
        response = await ApiPath.put(
          `/products/${initialValues._id}`,
          jsonResult[0]
        );
        if (response.data) {
          message.success("Products submitted successfully");
          window.location.reload();
        }
      } else {
        // Otherwise, it's a new product creation (POST request)
        response = await ApiPath.post("/products", jsonResult);
      }
      if (response.status === 201 || response.status === 200) {
        message.success("Products submitted successfully");
        // Optionally redirect or reset form
      } else {
        message.error(`Error: ${response.data.message || "Submission failed"}`);
      }
    } catch (error) {
      console.error("Submission error:", error);
      message.error("An error occurred while submitting the form.");
    }
    setLoading(false);
  };

  // const handleFileChange = async (
  //   file,
  //   categoryIndex,
  //   subIndex,
  //   productIndex
  // ) => {
  //   setloadingImage(productIndex);

  //   // Check if the file is selected
  //   if (!file) {
  //     console.log("No file selected");
  //     return; // Exit if no file is selected
  //   }

  //   console.log("File selected: ", file);

  //   // Prepare the form data
  //   const formData = new FormData();
  //   formData.append("my-image-file", file, file.name);

  //   try {
  //     // Upload the image and wait for the response
  //     const response = await ApiPath.post("/upload", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data", // Ensure the correct content-type is set
  //       },
  //     });

  //     // Extract the image URL from the response
  //     const imageUrl = response.data.file;
  //     console.log("Image URL: ", imageUrl);

  //     // Update the state with the image URL
  //     const updatedCategories = [...categories];
  //     updatedCategories[categoryIndex].subcategories[subIndex].products[
  //       productIndex
  //     ].image = imageUrl;
  //     setCategories(updatedCategories);
  //     console.log("Updated categories: ", updatedCategories);
  //   } catch (error) {
  //     console.error("Error uploading file: ", error);
  //   } finally {
  //     // Reset the loading state
  //     setloadingImage(null);
  //   }
  // };

  const handleFileChange = async (
    file,
    categoryIndex,
    subIndex,
    productIndex
  ) => {
    setloadingImage(productIndex);

    // Check if the file is selected
    if (!file) {
      console.log("No file selected");
      return; // Exit if no file is selected
    }
    console.log("File selected: ", file);
    const formData = new FormData();
    formData.append("image", file, file.name); // Ensure key matches PHP backend ('image')
    try {
      // Upload the image and wait for the response
      const response = await axios.post(
        "https://admin.tidyhub.ae/upload.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // Required for CORS with credentials
        }
      );
      // Extract the image URL from the response
      const imageUrl = response.data.filePath; // Match the response structure from PHP
      // Update the state with the image URL
      const updatedCategories = [...categories];
      updatedCategories[categoryIndex].subcategories[subIndex].products[
        productIndex
      ].image = imageUrl;
      setCategories(updatedCategories);
      console.log("Updated categories: ", updatedCategories);
    } catch (error) {
      console.error("Error uploading file: ", error);
    } finally {
      // Reset the loading state
      setloadingImage(null);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Button
        type="dashed"
        onClick={handleAddCategory}
        style={{ marginBottom: 16 }}
      >
        Add Product
      </Button>

      {categories.map((category, categoryIndex) => (
        <Card
          key={categoryIndex}
          title={`Product ${categoryIndex + 1}`}
          extra={
            <>
              <Button
                type="danger"
                onClick={() => handleDeleteCategory(categoryIndex)}
                style={{ marginRight: 8 }}
              >
                Delete Category
              </Button>
              <Button
                type="danger"
                onClick={() => handleDeleteAllProductsInCategory(categoryIndex)}
              >
                Delete All Products in Category
              </Button>
            </>
          }
          style={{ marginBottom: 16 }}
        >
          {category.name ? (
            <h3>{category.name}</h3>
          ) : (
            <Form.Item name="category.name" label="Parent">
              <Select
                showSearch
                placeholder="Select a category"
                optionFilterProp="children"
                name="category.name"
                style={{ textTransform: "capitalize" }}
                value={category.name} // Ensure it reflects the category's current value
                onChange={
                  (value) => handleCategoryChange(categoryIndex, "name", value) // Use 'value' directly
                }
              >
                {Parent.map((parent) => (
                  <Option
                    key={parent._id}
                    value={parent.title}
                    style={{ textTransform: "capitalize" }}
                  >
                    {parent?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Button
            type="dashed"
            onClick={() => handleAddSubcategory(categoryIndex)}
          >
            Add Categories
          </Button>

          {category.subcategories.map((subcategory, subIndex) => (
            <Card
              key={subIndex}
              // title={`Category ${subIndex + 1}`}
              extra={
                <>
                  <Button
                    type="danger"
                    onClick={() =>
                      handleDeleteSubcategory(categoryIndex, subIndex)
                    }
                    style={{ marginRight: 8 }}
                  >
                    Delete Category
                  </Button>
                  <Button
                    type="danger"
                    onClick={() =>
                      handleDeleteAllProducts(categoryIndex, subIndex)
                    }
                  >
                    Delete All Products
                  </Button>
                </>
              }
              style={{ marginBottom: 16 }}
            >
              {subcategory.subcategoryName ? (
                <Form.Item label="Category Name">
                  <Input
                    value={subcategory.subcategoryName}
                    onChange={(e) =>
                      handleSubcategoryChange(
                        categoryIndex,
                        subIndex,
                        "subcategoryName",
                        e.target.value
                      )
                    }
                    placeholder="Men or Women or Kids"
                  />
                </Form.Item>
              ) : (
                <Form.Item label="Category Name">
                  <Select
                    showSearch
                    placeholder="Select a category"
                    optionFilterProp="children"
                    // name="category.name"
                    style={{ textTransform: "capitalize" }}
                    // value={subcategory.subcategoryName} // Ensure value matches the selected subcategory
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) =>
                      handleSubcategoryChange(
                        categoryIndex,
                        subIndex,
                        "subcategoryName",
                        value // Use the selected value directly
                      )
                    }
                  >
                    {Category.map((category) => (
                      <Option
                        key={category.title} // Use a unique key for each category
                        value={category.title} // Use the category title as the value
                        style={{ textTransform: "capitalize" }}
                      >
                        {category.title} {/* Display the category title */}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {subcategory.products.map((product, productIndex) => (
                <Row key={productIndex} gutter={16}>
                  <Col span={4}>
                    <div>
                      {/* {loadingImage} */}
                      {loadingImage == productIndex ? (
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            marginTop: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        <div>
                          {product.image && (
                            <img
                              src={product.image}
                              alt="Product Preview"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                marginTop: "8px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Product Name">
                      <Input
                        value={product.product}
                        onChange={(e) =>
                          handleProductChange(
                            categoryIndex,
                            subIndex,
                            productIndex,
                            "product",
                            e.target.value
                          )
                        }
                        placeholder="Enter product name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Price">
                      <Input
                        type="number"
                        value={product.price}
                        onChange={(e) =>
                          handleProductChange(
                            categoryIndex,
                            subIndex,
                            productIndex,
                            "price",
                            parseFloat(e.target.value)
                          )
                        }
                        placeholder="Enter product price"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Product Image">
                      <Input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleFileChange(
                            e.target.files[0],
                            categoryIndex,
                            subIndex,
                            productIndex
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    span={4}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteOutlined
                      onClick={() =>
                        handleDeleteProduct(
                          categoryIndex,
                          subIndex,
                          productIndex
                        )
                      }
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={() => handleAddProduct(categoryIndex, subIndex)}
              >
                Add Product
              </Button>
            </Card>
          ))}
        </Card>
      ))}

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicProductForm;
