import React, { useEffect } from "react";
import "./Styles.css";
import logo from "../Img/Tidylogo.png";
import icon from "../Img/icon.png";
import ApiPath from "../hooks/ApiFetch";
import { convertToReadableDate } from "../Pages/Data/Data";

const Receipt = ({ id }) => {
  const [datax, setData] = React.useState({});

  const apicall = async () => {
    console.log("id", id);
    try {
      const res = await ApiPath(`/orders/one/${id}`);
      const datac = await res.data;
      setData(datac);
      console.log(datac);
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  useEffect(() => {
    apicall();
  }, [id]);
  const {
    ref,
    deliveryDate,
    deliveryAddress,
    deliveryFee,
    discount,
    items,
    subtotal,
    totalPrice,
    paymentmode,
    pickTime,
    createdAt,
    user,
  } = datax;
  const formatCurrency = (amount) => `AED ${amount}`;
  const timeCovert = (date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }).format(new Date(date));
  };

  return (
    <div className="">
      <header className="header">
        <div className="logo">
          <img
            src={logo}
            alt="My Dry Cleaning Co Logo"
            className="logo-image"
          />
          <br />
          <br />
          <p>Al Yasat Tower - Najda Street Al Danah - Zone 1 - Abu Dhabi</p>
          <p>
            Tel :<b>+971 244 89 841</b> | Mobile : <b>+ 971 50 195 1198</b>
          </p>
        </div>
      </header>

      <img className="image" src={icon} alt="icon" />

      <div className="receipt-details">
        <div align="center">
          <span
            style={{
              fontSize: 25,
              fontWeight: 800,
              textDecoration: "underline",
            }}
          >
            Receipt
          </span>
        </div>
        <div className="row">
          <ul>
            <li>Order : #{ref}</li>
            <li>
              <span style={{ fontSize: "15px" }}>Created: </span>{" "}
              <span>{new Date(createdAt).toLocaleString()}</span>{" "}
            </li>
          </ul>
          <ul>
            <li>Client : {user?.name}</li>
            <li>
              Email : <strong>{user?.email}</strong>
            </li>
          </ul>
          <ul>
            <li>
              <span style={{ fontSize: "15px" }}>
                Payment Method :{" "}
                <span
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  {paymentmode}
                </span>
              </span>
            </li>
            <li>
              Total : <strong>{formatCurrency(totalPrice)}</strong>
            </li>
          </ul>
          <ul>
            <li>
              <span style={{ fontSize: "15px" }}>
                PickUp Date & Time : <br />
                <span style={{ fontSize: 13 }}>
                  <b>{pickTime ? convertToReadableDate(pickTime) : "N/A"}</b>
                </span>
              </span>
            </li>
            <li>
              <p>
                Delivery Date & Time : <br />
                <span style={{ fontSize: 13 }}>
                  <b>
                    {deliveryDate ? convertToReadableDate(deliveryDate) : "N/A"}
                  </b>
                </span>
              </p>
            </li>
          </ul>
        </div>

        {/* <div className="row">
          <div className="col" align="right" style={{ background: "" }}>
            <div style={{ marginBottom: 10 }}></div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontSize: "15px" }}>
                Payment Method :{" "}
                <span
                  style={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  {paymentmode}
                </span>
              </span>
            </div>
            <div style={{ marginBottom: 10 }}>
              <span style={{ fontSize: "15px" }}>
                PickUp Date & Time : <br />
                <span style={{ fontSize: 13 }}>
                  <b>{pickTime ? convertToReadableDate(pickTime) : "N/A"}</b>
                </span>
              </span>
            </div>
          </div>
          <div className="col" align="left">
            <div style={{ marginBottom: 15 }}></div>
            <div style={{ marginBottom: 10 }}>
              Email : <strong>{user?.email}</strong>
            </div>
            <div style={{ marginBottom: 16 }}>
              Total : <strong>{formatCurrency(totalPrice)}</strong>
            </div>
            <div style={{ marginBottom: 15 }}>
              <p>
                Delivery Date & Time : <br />
                <span style={{ fontSize: 13 }}>
                  <b>
                    {deliveryDate ? convertToReadableDate(deliveryDate) : "N/A"}
                  </b>
                </span>
              </p>
            </div>
          </div>
        </div> */}
      </div>

      <div>
        <p align="center">
          Delivery Address : <br />{" "}
          <b>
            {deliveryAddress?.address} | {user?.mobileNumber}
          </b>
        </p>
      </div>

      <table className="items-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Item</th>
            <th>Service</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: 13 }}>
          {items &&
            Object.values(items).map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {item.product}
                  </span>
                </td>
                <td>Ironing</td>
                <td>{item.quantity}</td>
                <td>{formatCurrency(item.price)}</td>
                <td>{formatCurrency(item.price * item.quantity)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <section className="summary">
        <p>
          Subtotal: <span>{formatCurrency(subtotal)}</span>
        </p>
        <p>
          Delivery Fee: <span>{formatCurrency(deliveryFee)}</span>
        </p>
        <p>
          Discount: <span>{formatCurrency(discount)}</span>
        </p>
        <p>
          Vat: <span>{formatCurrency(discount)}</span>
        </p>
        <h3>
          Total: <span>{formatCurrency(totalPrice)}</span>
        </h3>
      </section>
    </div>
  );
};

export default Receipt;
