import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddUserForm from "./Add"; // Adjust the path as necessary
import { FAQ } from "../../Data/Data";
import axios from "axios";
import fetchApi from "../../../hooks/ApiFetch";

const { Option } = Select;

const ContactUs = () => {
  axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchName, setSearchName] = useState(""); // Search term for name
  const [searchEmail, setSearchEmail] = useState(""); // Search term for email
  const [searchMobileNumber, setSearchMobileNumber] = useState(""); // Search term for mobile number
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApi.get(`/contactus`);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddUser = () => {
    setEditingUser(null); // Reset for adding a new user
    // form.setFieldsValue(null); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user); // Set the user to be edited
    // console.log("User", user);
    form.setFieldsValue(user); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      // content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await fetchApi.delete(`/contactus/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Data Deleted",
            description: "Data has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the Data.",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async (values) => {
    try {
      if (editingUser) {
        // Update existing user
        await fetchApi.put(`/contactus/${editingUser._id}`, values);
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Data Updated",
          description: "Data has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await fetchApi.post(`/contactus`, values);
        setData((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Data Added",
          description: "Data has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };

  const filteredData = data.filter((item) => {
    // return item.title.toLowerCase().includes(searchName.toLowerCase());
    return item;
    // &&
    // item.email.toLowerCase().includes(searchEmail.toLowerCase()) &&
    // item.mobileNumber.toLowerCase().includes(searchMobileNumber.toLowerCase())
  });

  const columns = [
    {
      title: (
        <div>
          Icon
          <Input
            placeholder="Search Icon"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "icon",
      key: "icon",
    },
    {
      title: (
        <div>
          Details
          <Input
            placeholder="Search Details"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "details",
      key: "details",
    },
    {
      title: (
        <div>
          Label
          <Input
            placeholder="Search Label"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "label",
      key: "label",
    },
    {
      title: (
        <div>
          Link
          <Input
            placeholder="Search Link"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record.key)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Contact Us</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Add Contact Us
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false} // Disable default pagination
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingUser ? "Edit Contact Us" : "Add Contact Us"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddUserForm
          form={form}
          onFinish={handleOk}
          initialValues={editingUser || {}}
        />
      </Modal>
    </div>
  );
};

export default ContactUs;
