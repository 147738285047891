import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Typography,
  Select,
  Switch,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { AuthContext } from "../../../context/Context";
import dayjs from "dayjs";
import axios from "axios";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const AddCouponForm = ({
  form,
  onFinish,
  initialValues,
  edit,
  uploadedFile,
  setUploadedFile,
}) => {
  const { Category, Vendors } = useContext(AuthContext);

  const [file, setFile] = useState(null);
  const [loadingImage, setloadingImage] = useState(false);

  const handleFileChange = async (event) => {
    setloadingImage(true);
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    setFile(selectedFile);

    // Check the image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(selectedFile);
    img.onload = async () => {
      if (img.width !== 560 || img.height !== 335) {
        alert("Image dimensions must be 569 x 335 pixels.");
        setloadingImage(false);
        return;
      }

      // Prepare the file for upload
      const formData = new FormData();
      formData.append("image", selectedFile);
      try {
        // Upload the image and wait for the response
        const response = await axios.post(
          "https://admin.tidyhub.ae/uploadsystem.php",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            withCredentials: true, // Required for CORS with credentials
          }
        );
        // Extract the image URL from the response
        const imageUrl = response.data.filePath; // Match the response structure from PHP
        // Update the state with the image URL
        setUploadedFile(imageUrl);
        console.log("imageUrl", imageUrl);
        // alert("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file: ", error);
      } finally {
        // Reset the loading state
        // setloadingImage(null);
      }
      setloadingImage(false);
    };
  };

  const [metaFields, setMetaFields] = useState(
    initialValues?.metaFields || [{ key: "", value: "" }]
  );

  const addMetaField = () => {
    setMetaFields([...metaFields, { key: "", value: "" }]);
  };

  const removeMetaField = (index) => {
    const newMetaFields = metaFields.filter((_, i) => i !== index);
    setMetaFields(newMetaFields);
  };

  const handleMetaFieldChange = (index, field, value) => {
    const newMetaFields = [...metaFields];
    newMetaFields[index][field] = value;
    setMetaFields(newMetaFields);
  };

  const dateFormat = "DD-MM-YYYY";

  const handleFinish = (values) => {
    const formattedValues = {
      ...values,
      metaFields,
      startDate: values.startDate
        ? dayjs(values.startDate).format(dateFormat)
        : null,
      endDate: values.endDate ? dayjs(values.endDate).format(dateFormat) : null,
    };
    console.log("formattedValues", formattedValues);
    onFinish(formattedValues); // Pass the formatted values to the parent component
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      style={{ margin: "auto", padding: "10px" }}
    >
      <Form.Item
        label="Coupon Code"
        name="detail"
        rules={[{ required: true, message: "Please input your detail!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Enter your detail" />
      </Form.Item>
      <Form.Item
        label="Coupon Details"
        name="title"
        rules={[{ required: true, message: "Please input your title!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Enter Title English" />
      </Form.Item>

      <span>Image</span>
      <Row>
        <Col>
          <div>
            {/* {UploadedFile} */}
            <div align="center">
              {uploadedFile && (
                <img
                  src={uploadedFile}
                  alt="Uploaded File"
                  style={{ width: "140px", height: "auto" }}
                />
              )}
            </div>
          </div>
        </Col>
        <Col>
          {/* <Form.Item
            label="Code"
            name="code"
            rules={[{ required: true, message: "Please input your code!" }]}
            style={{ marginBottom: "8px" }}
          >
            <Input placeholder="Enter your code" value={uploadedFile} />
          </Form.Item> */}
          {loadingImage ? (
            <span>Uploading image....</span>
          ) : (
            <Button
              variant="outlined"
              component="label"
              fullWidth
              style={{ marginTop: "16px", marginBottom: "16px" }}
            >
              <input type="file" onChange={handleFileChange} />
            </Button>
          )}
        </Col>
      </Row>

      <Form.Item
        label="Type"
        name="type"
        rules={[{ required: true, message: "Please input your type!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Select placeholder="Select Type">
          <Option value="Fixed">Fixed</Option>
          <Option value="Flexible">Flexible</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Discount Type"
        name="DiscountType"
        rules={[{ required: true, message: "Please input your type!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Select placeholder="Select Type">
          <Option value="%">Percentage</Option>
          <Option value="AED">Amount</Option>
        </Select>
      </Form.Item>
      {/* <Form.Item
        label="Minimum Amount"
        name="MinimumAmount"
        rules={[
          { required: true, message: "Please input the minimum amount!" },
        ]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Minimum" />
      </Form.Item> */}
      <Form.Item
        label="Unit"
        name="Unit"
        rules={[{ required: true, message: "Please input your unit!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Unit" />
      </Form.Item>

      <Form.Item
        label="Status"
        name="status"
        valuePropName="checked"
        style={{ marginBottom: "8px" }}
      >
        <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
      </Form.Item>
      {/* <Form.Item
        name="vendors"
        label="Vendors"
        // rules={[{ required: true, message: "Vendors is required" }]}
      >
        <Select mode="multiple" placeholder="Select vendors">
          {Vendors.map((a) => (
            <Option key={a._id} value={a._id}>
              {a?.name}
            </Option>
          ))}
        </Select>
      </Form.Item> */}

      {/* <Form.Item
        label="Expire At"
        name="expire"
        style={{ marginBottom: "8px" }}
      >
        <RangePicker format={dateFormat} />
      </Form.Item> */}
      {/* <Form.Item label="Meta Fields">
        {metaFields.map((metaField, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
            <Col span={11}>
              <Input
                placeholder="Key"
                value={metaField.key}
                onChange={(e) =>
                  handleMetaFieldChange(index, "key", e.target.value)
                }
              />
            </Col>
            <Col span={11}>
              <Input
                placeholder="Value"
                value={metaField.value}
                onChange={(e) =>
                  handleMetaFieldChange(index, "value", e.target.value)
                }
              />
            </Col>
            <Col span={2}>
              <Button
                type="danger"
                icon={<MinusCircleOutlined />}
                onClick={() => removeMetaField(index)}
              />
            </Col>
          </Row>
        ))}
        <Button type="dashed" onClick={addMetaField} icon={<PlusOutlined />}>
          Add Meta Fields
        </Button>
      </Form.Item> */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCouponForm;
