import React, { useContext, useState } from "react";
import { Card, Row, Col, Statistic, Table, Input, Select, Button } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { Orders } from "../Data/Data"; // Adjust the path as necessary
import { AuthContext } from "../../context/Context";
const { Option } = Select;
const Dashboard = () => {
  const [filteredData, setFilteredData] = useState(Orders);
  const [searchName, setSearchName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const {
    Users,
    order,
    fetchOrder,
    setorders: setData,
  } = useContext(AuthContext);
  // const [data, setorders as setData] = useState([]);
  // Filter orders based on search and status
  const filterData = () => {
    setFilteredData(
      Orders.filter(
        (item) =>
          item.name.toLowerCase().includes(searchName.toLowerCase()) &&
          (selectedStatus ? item.status === selectedStatus : true)
      )
    );
  };
  // Calculate order totals
  const totalAmount = filteredData.reduce(
    (total, item) => total + parseFloat(item.total),
    0
  );
  // Prepare data for line chart
  const chartData = Orders.map((order) => ({
    date: new Date(order.createdAt).toLocaleDateString(),
    total: parseFloat(order.total),
  }));
  // Prepare data for pie chart
  const statusData = [
    {
      name: "Processing",
      value: Orders.filter((o) => o.status === "processing").length,
    },
    {
      name: "Completed",
      value: Orders.filter((o) => o.status === "completed").length,
    },
    {
      name: "Awaiting Payment",
      value: Orders.filter((o) => o.status === "awaiting_payment").length,
    },
    {
      name: "Shipped",
      value: Orders.filter((o) => o.status === "shipped").length,
    },
    {
      name: "Delivered",
      value: Orders.filter((o) => o.status === "delivered").length,
    },
  ];

    const getName = (_id) => {
      console.log("data", Users);
      const user = Users?.find((user) => user._id === _id);
      return user ? user.name : "Unknown";
    };

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4C4C"];

  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={16}>
        <Col span={8}>
          <Card title="Order Totals">
            <Statistic
              title="Total Amount"
              value={`$${totalAmount.toFixed(2)}`}
              prefix="$"
            />
          </Card>
          <br />
          <Card title="Filters">
            <Input
              placeholder="Search by Name"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <Select
              placeholder="Select Status"
              style={{ width: "100%", marginBottom: "10px" }}
              onChange={(value) => setSelectedStatus(value)}
            >
              <Option value="">All</Option>
              <Option value="processing">Processing</Option>
              <Option value="completed">Completed</Option>
              <Option value="awaiting_payment">Awaiting Payment</Option>
              <Option value="shipped">Shipped</Option>
              <Option value="delivered">Delivered</Option>
            </Select>
            <Button type="primary" onClick={filterData}>
              Apply Filters
            </Button>
          </Card>
        </Col>
        <Col span={16} style={{ textAlign: "center" }}>
          <Card title="Order Trends" style={{ marginBottom: "20px" }}>
            <LineChart
              width={600}
              height={300}
              data={chartData}
              style={{ margin: "0 auto" }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="total" stroke="#8884d8" />
            </LineChart>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "20px", textAlign: "center" }}>
        <Col span={12}>
          <Card title="Recent Orders">
            <Table
              dataSource={order}
              columns={[
                { title: "ID", dataIndex: "ref", key: "ref" },
                {
                  title: "Name",
                  dataIndex: "userId",
                  key: "userId",
                  render: (userx) => <div>{getName(userx)}</div>,
                },
                {
                  title: "Total",
                  dataIndex: "totalPrice",
                  key: "totalPrice",
                  render: (data) => (
                    <div>
                      <b>{data} AED</b>
                    </div>
                  ),
                },
                { title: "Status", dataIndex: "status", key: "status" },
                {
                  title: "Payment Status",
                  dataIndex: "balance",
                  key: "balance",
                  render: (data) => (
                    <div>
                      <b>
                        {data > 0 ? <span>Not Paid</span> : <span>Paid</span>}
                      </b>
                    </div>
                  ),
                },
                {
                  title: "Created At",
                  dataIndex: "createdAt",
                  key: "createdAt",
                  render: (date) => new Date(date).toLocaleString(),
                },
              ]}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Row gutter={16}>
            <Col span={24}>
              <Card
                title="Order Status Distribution"
                style={{ textAlign: "center" }}
              >
                <PieChart width={400} height={300}>
                  <Pie
                    data={statusData}
                    dataKey="value"
                    nameKey="name"
                    outerRadius={150}
                    fill="#8884d8"
                    label
                  >
                    {statusData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </Card>
            </Col>
            <Col span={24}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
