import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  Switch,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddForm from "./Add"; // Adjust the path as necessary
import { payments } from "../../Data/Data"; // Ensure this path is correct
import axios from "axios";
import { API_URL } from "../../../hooks/Api";

const { Option } = Select;

const App = () => {
  axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingPayment, setEditingPayment] = useState(null); // Track the payment being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchTitle, setSearchTitle] = useState(""); // Search term for title
  const [searchSlug, setSearchSlug] = useState(""); // Search term for slug
  const [searchMeta, setSearchMeta] = useState(""); // Search term for meta
  const [searchEnabled, setSearchEnabled] = useState(null); // Search term for enabled
  const [searchType, setSearchType] = useState(""); // Search term for type
  // const [data, setData] = useState(payments);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/paymentmethods`);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddPayment = () => {
    setEditingPayment(null); // Reset for adding a new user
    setIsModalVisible(true);
  };

  const handleEditPayment = (user) => {
    setEditingPayment(user); // Set the user to be edited
    form.setFieldsValue(user); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleDeletePayment = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/paymentmethods/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Payment Methods Deleted",
            description: "Payment Methods has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the order.",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async (values) => {
    try {
      if (editingPayment) {
        // Update existing user
        await axios.put(
          `${API_URL}/paymentmethods/${editingPayment._id}`,
          values
        );
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingPayment._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Payment Methods Updated",
          description: "Payment Methods has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await axios.post(`${API_URL}/paymentmethods`, values);
        setData((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Payment Methods Added",
          description: "Payment Methods has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };
  const filteredData = data.filter((item) => {
    return (
      item.title?.toLowerCase().includes(searchTitle.toLowerCase()) &&
      item.slug?.toLowerCase().includes(searchSlug.toLowerCase()) &&
      item.meta?.toLowerCase().includes(searchMeta.toLowerCase()) &&
      (searchEnabled === null || item.enabled === searchEnabled) &&
      item.type?.toLowerCase().includes(searchType.toLowerCase())
    );
  });

  const columns = [
    {
      title: (
        <div>
          Title
          <Input
            placeholder="Search Title"
            value={searchTitle}
            onChange={(e) => setSearchTitle(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <div>
          Slug
          <Input
            placeholder="Search Slug"
            value={searchSlug}
            onChange={(e) => setSearchSlug(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: (
        <div>
          Meta
          <Input
            placeholder="Search Meta"
            value={searchMeta}
            onChange={(e) => setSearchMeta(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "meta",
      key: "meta",
    },
    {
      title: (
        <div>
          Enabled
          <Select
            placeholder="Search Enabled"
            onChange={(value) => setSearchEnabled(value)}
            style={{ width: 120, marginTop: 8 }}
            allowClear
          >
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </div>
      ),
      dataIndex: "enabled",
      key: "enabled",
      render: (_, { enabled }) => (enabled ? "Yes" : "No"),
    },
    {
      title: (
        <div>
          Type
          <Input
            placeholder="Search Type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditPayment(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeletePayment(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Payments</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddPayment}
          >
            Add Payment
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false} // Disable default pagination
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingPayment ? "Edit Payment" : "Add Payment"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddForm
          form={form}
          onFinish={handleOk}
          initialValues={editingPayment || {}}
        />
      </Modal>
    </div>
  );
};

export default App;
