// AuthContext.js
import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { API_URL } from "../hooks/Api";
import { message } from "antd";
import ApiPath from "../hooks/ApiFetch";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [Category, setCategory] = useState([]);
  const [Vendors, setVendor] = useState([]);
  const [Users, setUser] = useState([]);
  const [Parent, setParent] = useState([]);
  const [order, setorders] = useState([]);
  const [Products, setProducts] = useState([]);
  // const [Products, setProducts] = useState([]);
  const [ProductsTwo, setProductTwo] = useState([]);
  const [coupons, setcoupons] = useState([]);

  const fetchUser = async () => {
    try {
      const response = await ApiPath.get(`/users`);
      setUser(response.data);
    } catch (error) {
      message.error("Failed to xx fetch data.");
    }
  };
  const fetchCoupons = async () => {
    try {
      const response = await ApiPath.get(`/coupons`);
      setcoupons(response.data);
    } catch (error) {
      // message.error("Failed to fetch data.");
    }
  };
  const fetchProduct = async () => {
    try {
      const response = await ApiPath.get(`/products`);
      const formattedData = [];
      response.data.forEach((product) => {
        const { name, categories } = product;
        // Loop through each category in the product (Men, Women, Children, All)
        for (let category in categories) {
          // Loop through the array of items in each category
          categories[category].forEach((item, index) => {
            formattedData.push({
              id: `${product._id}-${category}-${index}`, // Create a unique id for each item
              itemId: `${product._id}`, // Create a unique id for each item
              category: category, // Men, Women, Children, or All
              product: item.product, // Name of the product
              image: item.image, // Name of the product
              price: item.price, // Price of the product
              service: name, // Name of the main service (e.g. "Dry Cleaning", "Ironing")
            });
          });
        }
      });
      // console.log("TETTE", formattedData);
      setProductTwo(response.data);
    } catch (error) {
      // message.error("FFFFS.");
    }
  };
  const fetchCategory = async () => {
    try {
      const response = await ApiPath.get(`/categories`);
      setCategory(response.data);
    } catch (error) {
      // message.error("Failed to fetch data.");
    }
  };
  const fetchVendors = async () => {
    try {
      const response = await ApiPath.get(`/vendors`);
      setVendor(response.data);
    } catch (error) {
      // message.error("Failed to fetch data.");
    }
  };
  const fetchParent = async () => {
    try {
      const response = await ApiPath.get(`/parent`);
      setParent(response.data);
    } catch (error) {
      // message.error("Failed to fetch data.");
    }
  };
  const fetchOrder = async () => {
    try {
      const response = await ApiPath.get(`/orders`);
      console.log("orders");
      setorders(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };
  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
  };

  useEffect(() => {
    getAllApi();
  }, []);

  const getAllApi = () => {
    fetchOrder();
    fetchCategory();
    fetchVendors();
    fetchParent();
    fetchUser();
    fetchProduct();
    fetchCoupons();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        order,
        login,
        logout,
        setIsLoggedIn,
        Category,
        Vendors,
        Parent,
        Users,
        setUser,
        Products,
        setProducts,
        coupons,
        setcoupons,
        Parent,
        fetchProduct,
        setParent,
        fetchParent,
        fetchCoupons,
        fetchOrder,
        getAllApi,
        order,
        fetchUser,
        setorders,
        Category,
        setCategory,
        ProductsTwo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
