import React from "react";
import { Form, Input, Button, Card } from "antd";
import "./SettingsForm.css"; // Import the CSS file
import axios from "axios";

const { TextArea } = Input;

const SettingsForm = () => {
  axios.defaults.withCredentials = true;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Form Values:", values);
    // Handle form submission here
  };

  return (
    <Card title="Update Settings" className="settings-card">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          currency_code: "",
          currency_icon: "",
          tax_in_percent: "",
          support_email: "",
          support_phone: "",
          delivery_fee: "",
          delivery_fee_set_by: "",
          delivery_fee_per_km_charge: "",
          distance_metric: "",
          refer_amount: "",
          delivery_distance: "",
          privacy_policy: "",
          about_us: "",
          terms: "",
          admin_commision_type: "",
          admin_commision_value: "",
        }}
      >
        <Form.Item label="Currency Code" name="currency_code">
          <TextArea placeholder="currency_code" />
        </Form.Item>

        <Form.Item label="Currency Icon" name="currency_icon">
          <TextArea placeholder="currency_icon" />
        </Form.Item>

        <Form.Item label="Tax in Percent" name="tax_in_percent">
          <TextArea placeholder="tax_in_percent" />
        </Form.Item>

        <Form.Item label="Support Email" name="support_email">
          <TextArea placeholder="support_email" />
        </Form.Item>

        <Form.Item label="Support Phone" name="support_phone">
          <TextArea placeholder="support_phone" />
        </Form.Item>

        <Form.Item label="Delivery Fee" name="delivery_fee">
          <TextArea placeholder="delivery_fee" />
        </Form.Item>

        <Form.Item label="Delivery Fee Set By" name="delivery_fee_set_by">
          <TextArea placeholder="delivery_fee_set_by" />
        </Form.Item>

        <Form.Item
          label="Delivery Fee Per Km Charge"
          name="delivery_fee_per_km_charge"
        >
          <TextArea placeholder="delivery_fee_per_km_charge" />
        </Form.Item>

        <Form.Item label="Distance Metric" name="distance_metric">
          <TextArea placeholder="distance_metric" />
        </Form.Item>

        <Form.Item label="Refer Amount" name="refer_amount">
          <TextArea placeholder="refer_amount" />
        </Form.Item>

        <Form.Item label="Delivery Distance" name="delivery_distance">
          <TextArea placeholder="delivery_distance" />
        </Form.Item>

        <Form.Item label="Privacy Policy" name="privacy_policy">
          <TextArea placeholder="privacy_policy" />
        </Form.Item>

        <Form.Item label="About Us" name="about_us">
          <TextArea placeholder="about_us" />
        </Form.Item>

        <Form.Item label="Terms" name="terms">
          <TextArea placeholder="terms" />
        </Form.Item>

        <Form.Item label="Admin Commission Type" name="admin_commision_type">
          <TextArea placeholder="admin_commision_type" />
        </Form.Item>

        <Form.Item label="Admin Commission Value" name="admin_commision_value">
          <TextArea placeholder="admin_commision_value" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SettingsForm;
