import React from "react";
import { Card, Form, Input, Select, Button, Row, Col } from "antd";
import "./AppConfigurationForm.css"; // Import the CSS file for custom styles
import axios from "axios";

const { TextArea } = Input;
const { Option } = Select;

const AppConfigurationForm = () => {
  axios.defaults.withCredentials = true;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Form Values:", values);
    // Handle form submission here
  };

  return (
    <Card title="App Configuration" className="scrollable-card">
      <Form form={form} onFinish={onFinish} layout="vertical">
        {/* Timezone Configuration */}
        <Card title="App Configuration" className="sub-card">
          <Form.Item label="Timezone" name="app_timezone">
            <Select placeholder="Select Timezone" style={{ width: "100%" }}>
              <Option value="Asia/Bangkok">Asia/Bangkok</Option>
              {/* Add more options here */}
            </Select>
          </Form.Item>
        </Card>

        {/* Email Configuration */}
        <Card title="Email Configuration" className="sub-card">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="From Address" name="MAIL_FROM_ADDRESS">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="From Name" name="MAIL_FROM_NAME">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Mail Driver" name="mail_driver">
            <Select placeholder="Select Mail Driver" style={{ width: "100%" }}>
              <Option value="mailgun">Mailgun</Option>
              {/* Add more options here */}
            </Select>
          </Form.Item>
          <Form.Item label="Mailgun Domain" name="mailgun_domain">
            <Input />
          </Form.Item>
          <Form.Item label="Mailgun Secret" name="mailgun_secret">
            <Input />
          </Form.Item>
        </Card>

        {/* Push Notifications Configuration */}
        <Card title="Push Notifications" className="sub-card">
          <Form.Item
            label="Onesignal App ID for Customer App"
            name="ONESIGNAL_APP_ID_CUSTOMER"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Onesignal REST API for Customer App"
            name="ONESIGNAL_REST_API_CUSTOMER"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Onesignal App ID for Vendor App"
            name="ONESIGNAL_APP_ID_VENDOR"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Onesignal REST API for Vendor App"
            name="ONESIGNAL_REST_API_VENDOR"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Onesignal App ID for Driver App"
            name="ONESIGNAL_APP_ID_DRIVER"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Onesignal REST API for Driver App"
            name="ONESIGNAL_REST_API_DRIVER"
          >
            <Input />
          </Form.Item>
        </Card>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AppConfigurationForm;
