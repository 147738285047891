import React, { useEffect, useRef } from "react";
import Receipt from "./invoicepage";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ApiPath from "../hooks/ApiFetch";

function Invoice() {
  const { id } = useParams();
  const componentRef = useRef();

  return (
    <div className="">
      <ReactToPrint
        trigger={() => <button>Print this out!</button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef} className="receipt">
        <Receipt id={id} />
      </div>
    </div>
  );
}

export default Invoice;
