import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddForm from "./Add"; // Adjust the path as necessary
// import { Products } from "../../Data/Data";
import axios from "axios";
import { API_URL } from "../../../hooks/Api";
import { AuthContext } from "../../../context/Context";
import fetchApi from "../../../hooks/ApiFetch";
import ApiPath from "../../../hooks/ApiFetch";

const { Option } = Select;

const ProductsTwo = () => {
  // axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(8); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchName, setSearchName] = useState(""); // Search term for name
  const [searchEmail, setSearchEmail] = useState(""); // Search term for email
  const [searchMobileNumber, setSearchMobileNumber] = useState(""); // Search term for mobile number
  const { Products: data, setProducts: setData } = useContext(AuthContext);
  const [dataAll, setAllData] = useState([]);
  const { Category, Vendors } = useContext(AuthContext);
  const [base64Image, setBase64Image] = useState("");

  const handleFileChange = (info) => {
    const file = info.file;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiPath.get(`/products`);
      const formattedData = [];
      setAllData(response.data);
      // Loop through each product
      response.data.forEach((product) => {
        const { name, categories } = product;
        // Loop through each category in the product (Men, Women, Children, All)
        for (let category in categories) {
          // Loop through the array of items in each category
          categories[category].forEach((item, index) => {
            formattedData.push({
              id: `${product._id}-${category}-${index}`, // Create a unique id for each item
              itemId: `${product._id}`, // Create a unique id for each item
              category: category, // Men, Women, Children, or All
              product: item.product, // Name of the product
              image: item.image, // Name of the product
              price: item.price, // Price of the product
              service: name, // Name of the main service (e.g. "Dry Cleaning", "Ironing")
            });
          });
        }
      });
      // console.log("TETTE", formattedData);
      setData(formattedData);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddUser = () => {
    setEditingUser(null); // Reset for adding a new user
    form.setFieldsValue(null); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    const data = dataAll.find((item) => item._id == user.itemId);
    // console.log("user", user);
    // console.log("data", data);
    setEditingUser(data); // Set the user to be edited
    form.setFieldsValue(data); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      // content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/products/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Products Deleted",
            description: "Products has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the banner.",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setEditingUser(null);
    console.log("setEditingUser", setEditingUser);
    form.resetFields(); // Reset the form fields
    setIsModalVisible(false); // Hide the modal
  };

  const handleOk = async (values) => {
    const formData = {
      ...values,
      image: base64Image ? base64Image : values.image,
    };
    try {
      if (editingUser) {
        // Update existing user
        await axios.put(`${API_URL}/products/${editingUser._id}`, formData);
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...formData } : item
          )
        );
        notification.success({
          message: "Products Updated",
          description: "Products has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await axios.post(`/parent`, formData);
        setData((prevData) => [
          ...prevData,

          { ...formData, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Products Added",
          description: "Products has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };

  const filteredData = data.filter((item) => {
    return (
      item.product.toLowerCase().includes(searchName.toLowerCase()) &&
      item.service.toLowerCase().includes(searchEmail.toLowerCase())
    );
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, // Calculate index based on page
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (logo) => <img src={logo} alt="image" style={{ height: 30 }} />,
    },
    {
      title: (
        <div>
          Product
          <Input
            placeholder="Search product"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "product",
      key: "product",
    },
    {
      title: (
        <div>
          Service
          <Input
            placeholder="Search Detail"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "service",
      key: "service",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <div>{price} AED</div>,
    },
    {
      title: "Categories",
      dataIndex: "category",
      key: "category",
      render: (category) => (
        <div className="category">
          <span>{category}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const formattedColumns = columns.map((col) => ({
    ...col,
    onCell: (record) => ({
      style: { textTransform: "capitalize" },
    }),
  }));

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Products</h2>
        </Col>

        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Add Product
          </Button>
        </Col>
      </Row>
      <Table
        columns={formattedColumns}
        dataSource={filteredData.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )} // Slice data for current page
        pagination={false}
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={8}>8</Option>
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />

      <Modal
        title={editingUser ? "Edit Product" : "Add Product"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddForm
          form={form}
          onFinish={handleOk}
          initialValues={editingUser}
          // handleFileChange={handleFileChange}
          // base64Image={base64Image}
        />
      </Modal>
    </div>
  );
};

export default ProductsTwo;
