import React from "react";
import { Form, Input, Button, Select, List, Typography, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const UserProfile = ({
  form,
  onFinish,
  initialValues,
  handleFileChange,
  base64Image,
}) => {
  return (
    <Form
      form={form}
      onFinish={(values) => onFinish({ ...values })}
      layout="vertical"
      initialValues={initialValues}
    >
      {/* Name */}
      <Form.Item name="name" label="Name">
        <Input placeholder="Enter your name" />
      </Form.Item>
      {/* Email */}
      <Form.Item name="email" label="Email">
        <Input placeholder="Enter your email" />
      </Form.Item>

      {/* Mobile Verified */}
      <Form.Item name="mobileVerified" label="Mobile Verified">
        <Select>
          <Option value={true}>Verified</Option>
          <Option value={false}>Not Verified</Option>
        </Select>
      </Form.Item>

      {/* Language */}
      <Form.Item name="language" label="Language">
        <Select>
          <Option value="en">English</Option>
          <Option value="ar">Arabic</Option>
        </Select>
      </Form.Item>
      {/* User Balance */}
      <Form.Item name="userBalance" label="User Balance (AED)">
        <Input />
      </Form.Item>
      {/* Address List */}
      <Form.Item label="Addresses">
        <List
          bordered
          dataSource={initialValues?.address || []}
          renderItem={(item) => (
            <List.Item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography.Text strong>{item.address}</Typography.Text>
              <div>
                Apartment: {item.apartmentNumber || "N/A"} <br />
                Telephone: {item.telephone || "N/A"} <br />
                Lat: {item.latitude}, Lng: {item.longitude}
              </div>
            </List.Item>
          )}
        />
      </Form.Item>

      {/* Profile Image */}
      <div style={{ display: "flex" }}>
        <img
          src={base64Image ? base64Image : initialValues?.profilepic}
          width="150px"
          style={{ margin: "20px", marginLeft: "0px", borderRadius: 10 }}
          alt="Profile"
        />
        <Form.Item
          name="image"
          label="Profile Image"
          rules={[
            {
              required: true,
              message: "Please select a file!",
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={handleFileChange}
            style={{ width: "300px", background: "green" }}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
      </div>

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserProfile;
