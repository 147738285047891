export const Parent = [
  "dresser",
  "iron & fold",
  "dry clean",
  "handwash",
  "wash & fold",
];
export const Orders = [
  {
    id: 1,
    mobileNumber: "2345678901",
    email: "jane.doe@example.com",
    name: "Jane Doe",
    vendorName: "Supplier Co.",
    vendorAddress: "789 Supplier Rd.",
    vendorLatitude: "21.4567",
    vendorLongitude: "54.3210",
    deliveryAddress: "123 Delivery Blvd.",
    deliveryLatitude: "34.5678",
    deliveryLongitude: "87.6543",
    deliveryStatus: "Shipped",
    deliveryName: "John Smith",
    deliveryMobileNumber: "1234567890",
    total: "200.00",
    subtotal: "180.00",
    taxes: "10.00",
    deliveryFee: "5.00",
    discount: "5.00",
    paymentMethod: "PayPal",
    paymentStatus: "Pending",
    type: "Product",
    createdAt: "2024-08-06T08:30:00Z",
    status: "processing",
  },
  {
    id: 2,
    mobileNumber: "3456789012",
    email: "michael.smith@example.com",
    name: "Michael Smith",
    vendorName: "Retailer Ltd.",
    vendorAddress: "456 Retail Ave.",
    vendorLatitude: "45.6789",
    vendorLongitude: "32.1098",
    deliveryAddress: "789 Delivery Rd.",
    deliveryLatitude: "56.7890",
    deliveryLongitude: "98.7654",
    deliveryStatus: "Delivered",
    deliveryName: "Emily Brown",
    deliveryMobileNumber: "2345678901",
    total: "150.00",
    subtotal: "140.00",
    taxes: "7.00",
    deliveryFee: "3.00",
    discount: "0.00",
    paymentMethod: "Bank Transfer",
    paymentStatus: "Paid",
    type: "Service",
    createdAt: "2024-08-07T09:45:00Z",
    status: "completed",
  },
  {
    id: 3,
    mobileNumber: "4567890123",
    email: "lisa.johnson@example.com",
    name: "Lisa Johnson",
    vendorName: "Merchant Enterprises",
    vendorAddress: "123 Merchant St.",
    vendorLatitude: "67.8901",
    vendorLongitude: "21.0987",
    deliveryAddress: "234 Delivery Lane.",
    deliveryLatitude: "78.9012",
    deliveryLongitude: "12.3456",
    deliveryStatus: "Pending",
    deliveryName: "Robert Wilson",
    deliveryMobileNumber: "3456789012",
    total: "300.00",
    subtotal: "280.00",
    taxes: "15.00",
    deliveryFee: "10.00",
    discount: "5.00",
    paymentMethod: "Debit Card",
    paymentStatus: "Pending",
    type: "Product",
    createdAt: "2024-08-08T10:00:00Z",
    status: "awaiting_payment",
  },
  {
    id: 4,
    mobileNumber: "5678901234",
    email: "david.martin@example.com",
    name: "David Martin",
    vendorName: "Global Traders",
    vendorAddress: "567 Global St.",
    vendorLatitude: "12.3456",
    vendorLongitude: "65.4321",
    deliveryAddress: "345 Delivery Way.",
    deliveryLatitude: "89.0123",
    deliveryLongitude: "23.4567",
    deliveryStatus: "Shipped",
    deliveryName: "Sarah Clark",
    deliveryMobileNumber: "4567890123",
    total: "250.00",
    subtotal: "230.00",
    taxes: "12.00",
    deliveryFee: "8.00",
    discount: "0.00",
    paymentMethod: "Credit Card",
    paymentStatus: "Paid",
    type: "Service",
    createdAt: "2024-08-09T11:15:00Z",
    status: "shipped",
  },
  {
    id: 5,
    mobileNumber: "6789012345",
    email: "susan.lewis@example.com",
    name: "Susan Lewis",
    vendorName: "Market Goods",
    vendorAddress: "678 Market Rd.",
    vendorLatitude: "23.4567",
    vendorLongitude: "76.5432",
    deliveryAddress: "456 Delivery Blvd.",
    deliveryLatitude: "90.1234",
    deliveryLongitude: "34.5678",
    deliveryStatus: "Delivered",
    deliveryName: "Michael Taylor",
    deliveryMobileNumber: "5678901234",
    total: "400.00",
    subtotal: "370.00",
    taxes: "20.00",
    deliveryFee: "5.00",
    discount: "10.00",
    paymentMethod: "Wire Transfer",
    paymentStatus: "Paid",
    type: "Product",
    createdAt: "2024-08-10T12:30:00Z",
    status: "delivered",
  },
];
export const Vendors = [
  {
    email: "example1@example.com",
    mobile_number: "1234567890",
    password: "securePassword123",
    name: "John Doe",
    tagline: "Your Tagline Here",
    details: "Some detailed description or information for location 1.",
    categories: ["category1", "category2"],
    minimum_order: 10,
    delivery_fee: 5,
    area: "Downtown",
    address: "123 Main St",
    latitude: 40.7128,
    longitude: -74.006,
    image: null,
  },
  {
    email: "example2@example.com",
    mobile_number: "0987654321",
    password: "anotherSecurePassword123",
    name: "Jane Smith",
    tagline: "Another Tagline Here",
    details: "Some detailed description or information for location 2.",
    categories: ["category3", "category4"],
    minimum_order: 15,
    delivery_fee: 7,
    area: "Uptown",
    address: "456 Elm St",
    latitude: 34.0522,
    longitude: -118.2437,
    image: null,
  },
  {
    email: "example3@example.com",
    mobile_number: "1122334455",
    password: "yetAnotherSecurePassword",
    name: "Alice Johnson",
    tagline: "Yet Another Tagline Here",
    details: "Some detailed description or information for location 3.",
    categories: ["category1", "category5"],
    minimum_order: 20,
    delivery_fee: 10,
    area: "Midtown",
    address: "789 Oak St",
    latitude: 51.5074,
    longitude: -0.1278,
    image: null,
  },
  {
    email: "example4@example.com",
    mobile_number: "5566778899",
    password: "password1234",
    name: "Bob Brown",
    tagline: "Different Tagline Here",
    details: "Some detailed description or information for location 4.",
    categories: ["category2", "category6"],
    minimum_order: 25,
    delivery_fee: 12,
    area: "Suburbia",
    address: "101 Pine St",
    latitude: 37.7749,
    longitude: -122.4194,
    image: null,
  },
];
export const Products = [
  {
    title: "Winter Jacket",
    detail: "A warm and cozy winter jacket for cold weather.",
    categories: ["outerwear", "winter"],
    vendor_id: "vendor1",
    price: 99.99,
    stock_quantity: 50,
    image: null,
    metafields: [
      {
        key: "color",
        value: "blue",
      },
      {
        key: "size",
        value: "medium",
      },
    ],
  },
  {
    title: "Running Shoes",
    detail: "Comfortable running shoes for daily jogs.",
    categories: ["footwear", "sports"],
    vendor_id: "vendor2",
    price: 59.99,
    stock_quantity: 200,
    image: null,
    metafields: [
      {
        key: "color",
        value: "black",
      },
      {
        key: "size",
        value: "9",
      },
    ],
  },
  {
    title: "Leather Wallet",
    detail: "A stylish leather wallet with multiple card slots.",
    categories: ["accessories", "leather"],
    vendor_id: "vendor3",
    price: 39.99,
    stock_quantity: 150,
    image: null,
    metafields: [
      {
        key: "color",
        value: "brown",
      },
      {
        key: "material",
        value: "genuine leather",
      },
    ],
  },
  {
    title: "Bluetooth Headphones",
    detail: "Noise-canceling Bluetooth headphones with long battery life.",
    categories: ["electronics", "audio"],
    vendor_id: "vendor4",
    price: 149.99,
    stock_quantity: 75,
    image: null,
    metafields: [
      {
        key: "color",
        value: "black",
      },
      {
        key: "features",
        value: "wireless",
      },
    ],
  },
  {
    title: "Smartwatch",
    detail: "A smartwatch with fitness tracking and notification features.",
    categories: ["electronics", "wearables"],
    vendor_id: "vendor5",
    price: 199.99,
    stock_quantity: 30,
    image: null,
    metafields: [
      {
        key: "color",
        value: "silver",
      },
      {
        key: "battery_life",
        value: "48 hours",
      },
    ],
  },
];

export const deliveryAddress = [
  {
    email: "john.doe@example.com",
    mobile_number: "1234567890",
    password: "password123",
    assigned: true,
    is_online: true,
    is_verified: false,
    latitude: "40.7128",
    longitude: "-74.0060",
  },
  {
    email: "jane.smith@example.com",
    mobile_number: "9876543210",
    password: "securePass!1",
    assigned: false,
    is_online: true,
    is_verified: true,
    latitude: "34.0522",
    longitude: "-118.2437",
  },
  {
    email: "alex.jones@example.com",
    mobile_number: "5555555555",
    password: "MyP@ssw0rd",
    assigned: true,
    is_online: false,
    is_verified: true,
    latitude: "51.5074",
    longitude: "-0.1278",
  },
  {
    email: "maria.garcia@example.com",
    mobile_number: "6666666666",
    password: "Password1!",
    assigned: false,
    is_online: false,
    is_verified: false,
    latitude: "48.8566",
    longitude: "2.3522",
  },
  {
    email: "david.lee@example.com",
    mobile_number: "7777777777",
    password: "D@vidL33",
    assigned: true,
    is_online: true,
    is_verified: true,
    latitude: "39.9042",
    longitude: "116.4074",
  },
];

export const payments = [
  {
    title: "Cash On Delivery",
    slug: "cash-on-delivery",
    meta: "Description for Cash On Delivery",
    enabled: true,
    type: "cod",
  },
  {
    title: "Wallet",
    slug: "wallet",
    meta: "Description for Wallet",
    enabled: true,
    type: "wallet",
  },
  {
    title: "PayU",
    slug: "payu",
    meta: "Description for PayU",
    enabled: true,
    type: "payu",
  },
  {
    title: "Stripe",
    slug: "stripe",
    meta: "Description for Stripe",
    enabled: true,
    type: "stripe",
  },
  {
    title: "PayPal",
    slug: "paypal",
    meta: "Description for PayPal",
    enabled: true,
    type: "paypal",
  },
];

export const Transactions = [
  {
    id: 1,
    user: "Alice Johnson",
    amount: "$250",
    bankName: "First National Bank",
    bankAccount: "9876543210",
    bankAccountNumber: "123456789",
    bankCode: "FNB001",
    isPaid: true,
  },
  {
    id: 2,
    user: "Bob Smith",
    amount: "$175",
    bankName: "City Bank",
    bankAccount: "1122334455",
    bankAccountNumber: "556677889",
    bankCode: "CB002",
    isPaid: false,
  },
  {
    id: 3,
    user: "Carol White",
    amount: "$320",
    bankName: "National Bank",
    bankAccount: "2233445566",
    bankAccountNumber: "667788990",
    bankCode: "NB003",
    isPaid: true,
  },
  {
    id: 4,
    user: "David Green",
    amount: "$400",
    bankName: "Regional Bank",
    bankAccount: "3344556677",
    bankAccountNumber: "778899001",
    bankCode: "RB004",
    isPaid: false,
  },
  {
    id: 5,
    user: "Eva Brown",
    amount: "$150",
    bankName: "Global Bank",
    bankAccount: "4455667788",
    bankAccountNumber: "889900112",
    bankCode: "GB005",
    isPaid: true,
  },
  {
    id: 6,
    user: "Frank Harris",
    amount: "$275",
    bankName: "International Bank",
    bankAccount: "5566778899",
    bankAccountNumber: "990011223",
    bankCode: "IB006",
    isPaid: false,
  },
];

export const FAQ = [
  {
    title: "What types of laundry services do you offer?",
    shortDescription:
      "We offer various laundry services including wash and fold, dry cleaning, and carpet cleaning.",
    description:
      "Our laundry services include wash and fold for everyday clothes, dry cleaning for delicate or special items, and carpet cleaning for deep cleaning of carpets and rugs. We also offer sofa cleaning and other specialty services.",
  },
  {
    title: "How do I schedule a laundry pickup?",
    shortDescription: "You can schedule a pickup through our app or website.",
    description:
      "To schedule a pickup, use our app or visit our website. Simply select the service you need, choose a pickup date and time, and provide your address. You will receive a confirmation once your request is processed.",
  },
  {
    title: "What are your delivery and pickup hours?",
    shortDescription:
      "We offer flexible delivery and pickup hours to accommodate your schedule.",
    description:
      "Our delivery and pickup hours are typically from 8 AM to 8 PM, Monday through Saturday. We strive to offer flexible scheduling options to fit your needs. For specific hours in your area, please check our app or contact customer service.",
  },
  {
    title: "How do I track my laundry order?",
    shortDescription: "You can track your order using our app or website.",
    description:
      "Once your laundry is picked up, you can track its status through our app or website. You'll receive updates on the progress of your order and an estimated delivery time. You can also contact customer service for real-time updates.",
  },
  {
    title: "What should I do if I have a problem with my order?",
    shortDescription:
      "Contact our customer service for assistance with any issues.",
    description:
      "If you encounter any issues with your order, please reach out to our customer service team. We are here to help resolve any problems, whether it's a delay, damage, or any other concern. Contact us through our app, website, or by phone.",
  },
];

export const convertToReadableDate = (input) => {
  // Extract the timestamp part from the string
  const datePart = input.match(/\w{3} \w{3} \d{2} \d{4}/)?.[0];
  const startTime = input.match(/\d{1,2}:\d{2} [APM]{2}/)?.[0];

  if (!datePart || !startTime) {
    return "Invalid input format";
  }
  // Create a new date object from the extracted date part
  const date = new Date(datePart);
  // Format the date to dd/mm/yyyy
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based
  const year = date.getFullYear();
  // Format the time to hh:mm AM/PM
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;

  // Return formatted date in the form dd/mm/yyyy : time
  return `${day}/${month}/${year} : ${formattedTime}`;
};
