import React from "react";
import { Form, Input, Button, Upload, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { TextArea } = Input;

const AddUserForm = ({ form, onFinish, initialValues }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      style={{ margin: "auto", padding: "10px" }}
    >
      <Form.Item
        label="Icon"
        name="icon"
        rules={[{ required: true, message: "Please input your icon!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Enter your icon name" />
      </Form.Item>

      <Form.Item
        label="Label"
        name="label"
        rules={[{ required: true, message: "Please input your label!" }]}
        style={{ marginBottom: "8px" }}
      >
        {/* <TextArea type="email" placeholder="Enter your Description" /> */}
        <Input placeholder="Enter your label" />
      </Form.Item>
      <Form.Item
        label="Details"
        name="details"
        rules={[{ required: true, message: "Please input your details!" }]}
        style={{ marginBottom: "8px" }}
      >
        {/* <TextArea type="email" placeholder="Enter your Description" /> */}
        <Input placeholder="Enter your details" />
      </Form.Item>

      <Form.Item
        label="Link"
        name="link"
        rules={[{ required: true, message: "Please input your link!" }]}
        style={{ marginBottom: "8px" }}
      >
        {/* <TextArea placeholder="Enter your description" /> */}
        <Input placeholder="Enter your Link" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddUserForm;
