import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import AddCategory from "./Add";
import { API_URL } from "../../../hooks/Api";
import axios from "axios";
import fetchApi from "../../../hooks/ApiFetch";
import ApiPath from "../../../hooks/ApiFetch";
import { AuthContext } from "../../../context/Context";
const { Option } = Select;

const App = () => {
  // axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [searchMobileNumber, setSearchMobileNumber] = useState("");
  // const [data, setData] = useState([]);

  const { Category: data, setCategory: setData } = useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiPath.get(`/categories`);
      console.log("data", response);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddUser = () => {
    setEditingUser(null);
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user);
    form.setFieldsValue(user);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await ApiPath.delete(`/categories/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Category Deleted",
            description: "Category has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the Category.",
          });
        }
      },
    });
  };

  const handleOk = async (values) => {
    try {
      if (editingUser) {
        await ApiPath.put(`/categories/${editingUser._id}`, values);
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Banner Updated",
          description: "Banner has been updated successfully.",
        });
      } else {
        const response = await ApiPath.post(`/categories`, values);
        setData((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id },
        ]);
        notification.success({
          message: "Banner Added",
          description: "Banner has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handleStatusChange = (value, record) => {
    // Handle the status change logic here
    // For example, you can update the status in the backend
    console.log("Status changed:", value, record);
  };

  const filteredData = data.filter((item) => {
    return (
      item.title?.toLowerCase().includes(searchName?.toLowerCase()) &&
      item.slug?.toLowerCase().includes(searchEmail?.toLowerCase())
    );
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, // Calculate index based on page
    },
    {
      title: (
        <div>
          Title
          <Input
            placeholder="Search Title"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <div>
          Slug
          <Input
            placeholder="Search Slug"
            value={searchMobileNumber}
            onChange={(e) => setSearchMobileNumber(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: <div>parent</div>,
      dataIndex: "parent",
      key: "parent",
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Categories</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Add Category
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        footer={() => (
          <Row justify="space-between" align="middle">
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false}
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length}
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingUser ? "Edit Category" : "Add Category"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddCategory
          form={form}
          onFinish={handleOk}
          initialValues={editingUser || {}}
        />
      </Modal>
    </div>
  );
};

export default App;
