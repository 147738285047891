import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  Row,
  Col,
  Upload,
  message,
} from "antd";

const { Option } = Select;

const EditOrAddProductModal = ({
  isAdding,
  isModalOpen,
  handleModalSubmit,
  handleModalCancel,
  editingProduct,
  categories,
  selectedCategory,
}) => {
  const [useExistingCategory, setUseExistingCategory] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    // If editing a product, determine if the category exists
    if (editingProduct) {
      setUseExistingCategory(categories.includes(editingProduct.category));
    } else {
      setUseExistingCategory(true); // Default to existing categories when adding a product
    }
  }, [editingProduct, categories]);

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    // Replace with your actual upload URL
    const response = await fetch("/upload", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data.url; // Assuming the response contains the URL of the uploaded image
  };

  return (
    <Modal
      title={isAdding ? "Add Product" : "Edit Product"}
      open={isModalOpen}
      onCancel={handleModalCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        initialValues={
          editingProduct || {
            product: "",
            price: "",
            image: "",
            category: selectedCategory,
          }
        }
        onFinish={handleModalSubmit}
      >
        {/* Checkbox for Category Selection */}
        {!editingProduct && (
          <Form.Item>
            <Checkbox
              checked={useExistingCategory}
              onChange={(e) => setUseExistingCategory(e.target.checked)}
            >
              Use Existing Category
            </Checkbox>
          </Form.Item>
        )}
        {!editingProduct && (
          <div>
            {useExistingCategory ? (
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: "Please select a category!" },
                ]}
              >
                <Select placeholder="Select a category">
                  {categories.map((category) => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="New Category"
                name="category"
                rules={[
                  { required: true, message: "Please input a new category!" },
                ]}
              >
                <Input placeholder="Enter new category name" />
              </Form.Item>
            )}
          </div>
        )}
        {/* Product Name */}
        <Form.Item
          label="Product Name"
          name="product"
          rules={[
            { required: true, message: "Please input the product name!" },
          ]}
        >
          <Input placeholder="Enter product name" />
        </Form.Item>
        {/* Price */}
        <Form.Item
          label="Price"
          name="price"
          rules={[
            { required: true, message: "Please input the price!" },
            {
              type: "number",
              min: 0,
              message: "Price must be a positive number!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter price (e.g., 10)" />
        </Form.Item>
        <Form.Item
          label="Discount"
          name="discount"
          rules={[
            { required: true, message: "Please input the price!" },
            {
              type: "number",
              min: 0,
              message: "Price must be a positive number!",
            },
          ]}
        >
          <Input type="number" placeholder="Enter price (e.g., 10)" />
        </Form.Item>
        <Row>
          <Col>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.image !== currentValues.image
              }
            >
              {({ getFieldValue }) => {
                const imageUrl = getFieldValue("image");
                return imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="Product"
                    style={{ width: "100%", marginTop: 10, height: 100 }}
                  />
                ) : null;
              }}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Image URL"
              name="image"
              rules={[
                { required: true, message: "Please input the image URL!" },
                {
                  type: "url",
                  message: "Please enter a valid URL!",
                },
              ]}
            >
              <Input placeholder="Enter image URL" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Upload Image"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
        >
          <Upload
            name="image"
            listType="picture"
            beforeUpload={() => false} // Prevent automatic upload
            onChange={async (info) => {
              if (info.file.status === "done") {
                const imageUrl = await handleImageUpload(
                  info.file.originFileObj
                );
                form.setFieldsValue({ image: imageUrl });
              }
            }}
          >
            <Button>Upload Image</Button>
          </Upload>
        </Form.Item>
        {/* Display Image */}
        {/* Action Buttons */}
        <Form.Item>
          <Button
            type="primary"
            // htmlType="submit"

            onClick={() => message.info("George Fixing")}
          >
            {isAdding ? "Add" : "Save"}
          </Button>
          <Button onClick={handleModalCancel} style={{ marginLeft: 8 }}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOrAddProductModal;
