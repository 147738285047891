import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AddUserForm from "./Add"; // Adjust the path as necessary
import { API_URL } from "../../../hooks/Api";
import axios from "axios";
import fetchApi from "../../../hooks/ApiFetch";
import { AuthContext } from "../../../context/Context";
import ApiPath from "../../../hooks/ApiFetch";

const { Option } = Select;

const App = () => {
  // axios.defaults.withCredentials = true;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchName, setSearchName] = useState(""); // Search term for name
  const [searchEmail, setSearchEmail] = useState(""); // Search term for email
  const [searchMobileNumber, setSearchMobileNumber] = useState(""); // Search term for mobile number
  // const { Users, setUser } = AuthContext();
  const { Users, setUser } = useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiPath.get(`/users`);
      setUser(response.data);
    } catch (error) {
      message.error("Failed to Costomer fetch data.", error);
      console.log("Failed to Costomer fetch data.", error);
    }
  };

  const handleAddUser = () => {
    setEditingUser(null); // Reset for adding a new user
    // form.setFieldsValue(null); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user); // Set the user to be edited
    // console.log("User", user);
    form.setFieldsValue(user); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      // content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`${API_URL}/users/${id}`);
          setUser((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Banner Deleted",
            description: "Banner has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the banner.",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async (values) => {
    try {
      if (editingUser) {
        // Update existing user
        await axios.put(`${API_URL}/users/${editingUser._id}`, values);
        setUser((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Banner Updated",
          description: "Banner has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await axios.post(`${API_URL}/users`, values);
        setUser((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Banner Added",
          description: "Banner has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };

  const filteredData = Users.filter((item) => {
    return item;
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, // Calculate index based on page
    },
    {
      title: "Image",
      dataIndex: "profilepic",
      key: "index",
      render: (profilepic) =>
        profilepic ? (
          <img
            src={profilepic}
            alt="Profile"
            height={36}
            width={36}
            style={{ borderRadius: 5 }}
          />
        ) : (
          <img
            src="https://static-00.iconduck.com/assets.00/profile-circle-icon-512x512-zxne30hp.png"
            alt="Profile"
            height={36}
            width={36}
            style={{ borderRadius: 5 }}
          />
        ),
    },
    {
      title: (
        <div>
          Name
          <Input
            placeholder="Search Name"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (record) => (
        <div style={{ textTransform: "capitalize" }}>{record}</div>
      ),
    },
    {
      title: (
        <div>
          Email
          <Input
            placeholder="Search Email"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "email",
      key: "email",
    },
    {
      title: (
        <div>
          Mobile Number
          <Input
            placeholder="Search Mobile Number"
            value={searchMobileNumber}
            onChange={(e) => setSearchMobileNumber(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Balance",
      dataIndex: "userBalance",
      key: "userBalance",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record.key)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>User Management</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Add User
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        // dataSource={filteredData}
        dataSource={filteredData.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )} // Slice data for current page
        pagination={false} // Disable default pagination
        // pagination={{ pageSize: pageSize }}
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                dataSource={filteredData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )} // Slice data for current page
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingUser ? "Edit User" : "Add User"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddUserForm
          form={form}
          onFinish={handleOk}
          initialValues={editingUser || {}}
        />
      </Modal>
    </div>
  );
};

export default App;
