import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu, Button } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  TagOutlined,
  ProfileOutlined,
  ShopOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  TransactionOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
  CustomerServiceOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Cookies from "js-cookie"; // Import js-cookie
import menuData from "../Pages/Dashboard/menuData.json"; // Adjust path as necessary
import { AuthContext } from "../context/Context"; // Adjust path as necessary
import "./Styles.css";

const { Sider, Content, Header } = Layout;

const iconMap = {
  HomeOutlined: HomeOutlined,
  UserOutlined: UserOutlined,
  AppstoreAddOutlined: AppstoreAddOutlined,
  TagOutlined: TagOutlined,
  ProfileOutlined: ProfileOutlined,
  ShopOutlined: ShopOutlined,
  AppstoreOutlined: AppstoreOutlined,
  ShoppingCartOutlined: ShoppingCartOutlined,
  TransactionOutlined: TransactionOutlined,
  CreditCardOutlined: CreditCardOutlined,
  QuestionCircleOutlined: QuestionCircleOutlined,
  CustomerServiceOutlined: CustomerServiceOutlined,
  SettingOutlined: SettingOutlined,
};

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { getAllApi } = useContext(AuthContext);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    getAllApi();
    const authToken = Cookies.get("authToken"); // Retrieve the token from cookies
    if (!authToken) {
      navigate("/login"); // Redirect to login if token is missing
    }
  }, [navigate]);

  const renderMenuItems = (data) => {
    return data.map((item) => {
      const IconComponent = iconMap[item.icon];
      if (!IconComponent) {
        console.error(`Icon ${item.icon} not found in iconMap`);
        return null;
      }

      if (item.children && item.children.length > 0) {
        return (
          <Menu.SubMenu
            key={item.route}
            icon={<IconComponent />}
            title={
              <span style={{ color: "white" }}>
                {item.label}
                {(item.label === "Coupons" || item.label === "Products") && (
                  <label
                    style={{
                      position: "absolute",
                      paddingLeft: 10,
                      color: "red",
                    }}
                  >
                    new
                  </label>
                )}
              </span>
            }
            style={{ color: "white" }}
          >
            {item.children.map((subItem) => (
              <Menu.Item
                key={subItem.route}
                onClick={() => navigate(subItem.route)}
                style={{ color: "white" }}
              >
                {subItem.label}
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        );
      } else {
        return (
          <Menu.Item
            key={item.route}
            icon={<IconComponent />}
            onClick={() => navigate(item.route)}
            style={{ color: "white" }}
          >
            {item.label}
            {(item.label === "Coupons" || item.label === "Products") && (
              <label
                style={{
                  position: "absolute",
                  paddingLeft: 10,
                  color: "red",
                }}
              >
                new
              </label>
            )}
          </Menu.Item>
        );
      }
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="dashboard-header">
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{ marginRight: 16 }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </Header>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
          style={{
            backgroundColor: "#6500b2",
            position: "fixed",
            height: "100vh",
            left: 0,
          }}
        >
          <div
            style={{
              height: "32px",
              margin: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
          <Menu
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ backgroundColor: "#6500b2", color: "white" }}
          >
            {renderMenuItems(menuData)}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
          <Content className="dashboard-content">
            <div className="content-inner">
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
