import React from "react";
import { Form, Input, Row, Col, Button, Select, Card } from "antd";

const { Option } = Select;

const MyForm = ({ form, onFinish, initialValues }) => {
  console.log("initialValues,", initialValues.createdAt);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        ...initialValues,
        createdAt: new Date(initialValues?.createdAt).toLocaleString(),
      }}
      onFinish={onFinish}
    >
      <Row gutter={16}>
        {/* Order Information */}
        <Col span={24}>
          <Card title="Order Information" style={{ marginBottom: 24 }}>
            <Form.Item label="Created At" name="createdAt">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Order Reference" name="ref">
              <Input disabled />
            </Form.Item>
            <Form.Item label="Order Status" name="status">
              <Select style={{ width: "100%" }}>
                <Option value="active">Active</Option>
                <Option value="completed">Completed</Option>
                <Option value="cancelled">Cancelled</Option>
              </Select>
            </Form.Item>
            <Form.Item label="PickUp Driver" name="driver">
              <Input />
            </Form.Item>
            <Form.Item label="Stage" name="stagenow">
              <Select style={{ width: "100%" }}>
                {/* <Option value="Order Confirmed">Order Confirmed</Option> */}
                <Option value="Picked Up">Picked Up</Option>
                <Option value="In Progress">In Progress</Option>
                <Option value="Shipped">Shipped</Option>
                <Option value="Delivered">Delivered</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Delivery Date" name="deliveryDate">
              <Input disabled />
            </Form.Item>
          </Card>
        </Col>

        {/* Items Information */}
        <Col span={24}>
          <Card title="Items Information" style={{ marginBottom: 24 }}>
            {Object.values(initialValues.items).map((item, index) => (
              <Row key={index} gutter={16}>
                <Col span={2}>
                  <img src={item.image} width={30} />
                </Col>
                <Col span={10}>
                  <Form.Item label="Product">
                    <Input value={item.product} disabled />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Category">
                    <Input value={item.categoryName} disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Quantity">
                    <Input value={item.quantity} disabled />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item label="Price">
                    <Input value={item.price} disabled />
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Card>
        </Col>

        {/* Payment Information */}
        <Col span={24}>
          <Card title="Payment Information" style={{ marginBottom: 24 }}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Subtotal" name="subtotal">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Delivery Fee" name="deliveryFee">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Discount" name="discount">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Total" name="totalPrice">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Submit Button */}
        <Col span={24}>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default MyForm;
