import axios from "axios";
import Cookies from "js-cookie";

const secretKey = "galla0712@@";
axios.defaults.withCredentials = true;
const API_URL = "https://laundry-yq0p.onrender.com/api"; // Replace this with your API URL
// const API_URL = "http://localhost:8800/api"; // Replace this with your API URL
const ApiPath = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${secretKey}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
// Add response interceptor to handle 403 responses
ApiPath.interceptors.response.use(
  (response) => response, // Pass through any successful responses
  (error) => {
    console.log("error", error);
    if (error.response.status === 403) {
      // console.log("error.response.status === 403", error.response.status);
      Cookies.removeItem("Id");
      // router.push("/(auth)/login");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default ApiPath;
