import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  Typography,
  Space,
  Button,
  Modal,
  Form,
  Input,
  message,
  Switch,
} from "antd";
import EditOrAddProductModal from "./Modal/Add";
import { AuthContext } from "../../../context/Context";
import ApiPath from "../../../hooks/ApiFetch";

const { Title } = Typography;
const { Content } = Layout;

const Products = () => {
  const { ProductsTwo, fetchProduct } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedArray, setSelectedArray] = useState("ironing");
  const [selectedCategory, setSelectedCategory] = useState("Men");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  const arrayNames = data?.map((item) => item.name); // Get array names like "ironing", "Drying"
  const selectedData = data?.find((item) => item.name === selectedArray); // Get the selected array data

  useEffect(() => {
    if (ProductsTwo.length == 0) {
      // alert("No Array");
      fetchProducts();
    } else {
      setData(ProductsTwo);
    }
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await ApiPath.get(`/products`);
      const formattedData = [];
      response.data.forEach((product) => {
        const { name, categories } = product;
        // Loop through each category in the product (Men, Women, Children, All)
        for (let category in categories) {
          // Loop through the array of items in each category
          categories[category].forEach((item, index) => {
            formattedData.push({
              id: `${product._id}-${category}-${index}`, // Create a unique id for each item
              itemId: `${product._id}`, // Create a unique id for each item
              category: category, // Men, Women, Children, or All
              product: item.product, // Name of the product
              image: item.image, // Name of the product
              price: item.price, // Price of the product
              service: name, // Name of the main service (e.g. "Dry Cleaning", "Ironing")
            });
          });
        }
      });
      // console.log("TETTE", formattedData);
      setData(response.data);
    } catch (error) {
      // message.error("FFFFS.");
    }
  };

  const categories = selectedData ? Object.keys(selectedData.categories) : []; // Get categories for the selected array

  // Open modal for editing
  const handleEdit = (record) => {
    setEditingProduct(record);
    setIsModalOpen(true);
    setIsAdding(false);
  };

  // Open modal for adding
  const handleAdd = () => {
    setEditingProduct(null);
    setIsModalOpen(true);
    setIsAdding(true);
  };

  // Handle delete
  const handleDelete = (id) => {
    const updatedData = [...data];
    updatedData.forEach((item) => {
      if (item.name === selectedArray) {
        item.categories[selectedCategory] = item.categories[
          selectedCategory
        ].filter((product) => product.id !== id);
      }
    });
    setData(updatedData);
    message.success(`Product with ID ${id} deleted successfully.`);
  };

  // Handle activate
  const handleActivate = (id) => {
    message.success(`Product with ID ${id} activated successfully.`);
  };

  // Handle modal submission
  const handleModalSubmit = (values) => {
    const updatedProduct = { ...values, id: values.id || Date.now() };

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData.forEach((item) => {
        if (item.name === selectedArray) {
          if (isAdding) {
            const category = values.category;
            if (!item.categories[category]) {
              item.categories[category] = [];
            }
            item.categories[category].push(updatedProduct);
          } else {
            item.categories[selectedCategory] = item.categories[
              selectedCategory
            ].map((product) =>
              product.id === updatedProduct.id ? updatedProduct : product
            );
          }
        }
      });
      return updatedData;
    });

    message.success(
      isAdding ? "Product added successfully!" : "Product updated successfully!"
    );
    setIsModalOpen(false);
  };

  // Close the modal
  const handleModalCancel = () => {
    setIsModalOpen(false);
    setEditingProduct(null);
  };

  const productColumns = [
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
      render: (text, record) => (
        <Space>
          <img
            src={record.image}
            alt={text}
            style={{
              width: 40,
              height: 40,
              borderRadius: 4,
              textTransform: "capitalize",
            }}
          />
          {text}
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `AED ${price}`,
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Discount",
      key: "discount",
      render: (text, record) => <div>0 AED</div>,
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Space>
          <Form.Item
            // label="Status"
            // name="status"
            valuePropName="checked"
            style={{ marginBottom: "8px" }}
            onClick={() => message.error("George is still cooking")}
          >
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Layout style={{ padding: "24px" }}>
      <Content>
        <Row gutter={[16, 16]} style={{ height: "80vh" }}>
          {/* Column 1: Array Names */}
          <Col
            xs={24}
            sm={6}
            md={4}
            style={{ background: "#f5f5f5", padding: "16px" }}
          >
            <Title level={4}>Service Names</Title>
            {arrayNames.map((name) => (
              <div
                key={name}
                style={{
                  padding: "8px",
                  marginBottom: "8px",
                  cursor: "pointer",
                  background: selectedArray === name ? "#6500b2" : "#fff",
                  color: selectedArray === name ? "#fff" : "#000",
                  borderRadius: 4,
                  border: selectedArray === name ? "none" : "1px solid #d9d9d9",
                  textTransform: "Capitalize",
                }}
                onClick={() => setSelectedArray(name)}
              >
                {name}
              </div>
            ))}
          </Col>

          {/* Column 2: Categories and Products */}
          <Col
            xs={24}
            sm={18}
            md={20}
            style={{
              background: "#fafafa",
              padding: "16px",
              textAlign: "center",
            }}
          >
            <Row gutter={[16, 16]}>
              {/* Category list */}
              <Col span={4}>
                {categories.map((category) => (
                  <div
                    key={category}
                    style={{
                      padding: "8px",
                      marginBottom: "8px",
                      cursor: "pointer",
                      background:
                        selectedCategory === category ? "#6500b2" : "#fff",
                      color: selectedCategory === category ? "#fff" : "#000",
                      borderRadius: 4,
                      border:
                        selectedCategory === category
                          ? "none"
                          : "1px solid #d9d9d9",
                    }}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category == "" ? "Other" : category}
                  </div>
                ))}
              </Col>

              {/* Product Table */}
              <Col span={20}>
                <div align="left">
                  <h3
                    level={3}
                    style={{ textTransform: "capitalize" }}
                  >{`Categories in ${selectedArray}`}</h3>
                  <hr />
                  <Title level={5}>{`Products in ${selectedCategory}`}</Title>

                  <Row>
                    <Col span={12}>
                      <Input.Search
                        placeholder="Search Products"
                        onChange={(event) => {
                          const value = event.target.value;
                          if (value === "") {
                            setData(ProductsTwo);
                          } else {
                            const filteredData = data.map((item) => {
                              if (item.name === selectedArray) {
                                const filteredCategories = {};
                                for (let category in item.categories) {
                                  filteredCategories[category] =
                                    item.categories[category].filter(
                                      (product) =>
                                        product.product
                                          .toLowerCase()
                                          .includes(value.toLowerCase())
                                    );
                                }
                                return {
                                  ...item,
                                  categories: filteredCategories,
                                };
                              }
                              return item;
                            });
                            setData(filteredData);
                          }
                        }}
                        style={{ marginBottom: "16px" }}
                      />
                    </Col>
                    <Col span={12} align="right">
                      <Button
                        type="primary"
                        onClick={handleAdd}
                        style={{ marginBottom: "16px" }}
                      >
                        Add Product
                      </Button>
                    </Col>
                  </Row>
                </div>
                <Table
                  dataSource={
                    selectedData?.categories?.[selectedCategory] || []
                  }
                  columns={productColumns}
                  rowKey="id"
                  style={{ height: "78vh" }}
                  pagination={{ pageSize: 8 }}
                  bordered
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Edit/Add Modal */}
        <EditOrAddProductModal
          isAdding={isAdding}
          isModalOpen={isModalOpen}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalCancel}
          editingProduct={editingProduct}
          categories={categories}
          selectedCategory={selectedCategory}
        />
      </Content>
    </Layout>
  );
};

export default Products;
