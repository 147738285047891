import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddCategory from "./Add";
import { API_URL } from "../../../hooks/Api";
import axios from "axios";
import fetchApi from "../../../hooks/ApiFetch";
import { AuthContext } from "../../../context/Context";
import ApiPath from "../../../hooks/ApiFetch";

const { Option } = Select;
const Parent = () => {
  // axios.defaults.withCredentials = false;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [loading, setloading] = useState(false); // Track the user being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(6); // Default page size
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [searchName, setSearchName] = useState(""); // Search term for name
  const [searchEmail, setSearchEmail] = useState(""); // Search term for email
  const [searchMobileNumber, setSearchMobileNumber] = useState(""); // Search term for mobile number
  const [] = useState([
    // Add more data if needed for testing pagination
  ]);

  const {
    fetchParent,
    Parent: data,
    setParent: setData,
  } = useContext(AuthContext);

  const [base64Image, setBase64Image] = useState("");

  const handleFileChange = (info) => {
    const file = info.file;
    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64Image(reader.result);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiPath.get(`/parent`);
      console.log("data", response);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddUser = () => {
    setEditingUser(null); // Reset for adding a new user
    setIsModalVisible(true);
  };

  const handleEditUser = (user) => {
    setEditingUser(user); // Set the user to be edited
    form.setFieldsValue(user); // Populate the form with user data
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteUser = (id, title) => {
    Modal.confirm({
      title: "Are you sure you want to delete this item?",
      icon: <DeleteOutlined />,
      content: `Item: ${title}`,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await ApiPath.delete(`/parent/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Parent Deleted",
            description: "Parent has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the Parent.",
          });
        }
      },
    });
  };

  const handleOk = async (values) => {
    setloading(true);
    const formData = {
      ...values,
      image: base64Image ? base64Image : values.image,
    };
    try {
      if (editingUser) {
        // Update existing user
        await ApiPath.put(`/parent/${editingUser._id}`, formData);
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...values } : item
          )
        );
        notification.success({
          message: "Parent Updated",
          description: "Parent has been updated successfully.",
        });
      } else {
        // Add new user
        const response = await ApiPath.post(`/parent`, formData);
        setData((prevData) => [
          ...prevData,
          { ...values, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Parent Added",
          description: "Parent has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchParent();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
    }
    setloading(false);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page on page size change
  };

  const filteredData = data.filter((item) => {
    return (
      item.title?.toLowerCase().includes(searchName?.toLowerCase()) &&
      item.slug?.toLowerCase().includes(searchEmail?.toLowerCase())
      // item.mobileNumber.toLowerCase().includes(searchMobileNumber.toLowerCase())
    );
  });

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 100,
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, // Calculate index based on page
    },
    {
      title: "Icon",
      dataIndex: "image",
      key: "image",
      width: 150,
      render: (index) => (
        <div>
          <img src={index} height={40} />
        </div>
      ), // Calculate index based on page
    },
    {
      title: (
        <div>
          Title
          <Input
            placeholder="Search Title"
            value={searchEmail}
            onChange={(e) => setSearchEmail(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <div>
          Slug
          <Input
            placeholder="Search Slug"
            value={searchMobileNumber}
            onChange={(e) => setSearchMobileNumber(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "slug",
      key: "slug",
    },
    {
      title: <div>Description</div>,
      dataIndex: "description",
      key: "description",
    },
    {
      title: <div>Status</div>,
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (record) => (
        <div
          style={{
            textTransform: "capitalize",
            background: "lightgreen",
            borderRadius: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
          }}
        >
          {record}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditUser(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteUser(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Parent</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            Add Parent
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        // dataSource={filteredData}
        dataSource={filteredData.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )} // Slice data for current page
        pagination={false} // Disable default pagination
        // pagination={{ pageSize: pageSize }}
        footer={() => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                dataSource={filteredData.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )} // Slice data for current page
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                }}
                showSizeChanger={false} // Hide the default size changer
                showTotal={(total) => `Total   ${total} items`}
                total={filteredData.length} // Pass the total count
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingUser ? "Edit Parent" : "Add Parent"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddCategory
          form={form}
          onFinish={handleOk}
          initialValues={editingUser || {}}
          handleFileChange={handleFileChange}
          base64Image={base64Image}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default Parent;
