import React from "react";
import { Form, Input, Button, Upload, Typography } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { TextArea } = Input;

const AddUserForm = ({ form, onFinish, initialValues }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={initialValues}
      style={{ margin: "auto", padding: "10px" }}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please input your title!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Input placeholder="Enter your name" />
      </Form.Item>

      <Form.Item
        label="Short Description"
        name="shortDescription"
        rules={[{ required: true, message: "Please input your email!" }]}
        style={{ marginBottom: "8px" }}
      >
        <TextArea type="email" placeholder="Enter your email" />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[{ required: true, message: "Please input your description!" }]}
        style={{ marginBottom: "8px" }}
      >
        <TextArea placeholder="Enter your description" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
       
      </Form.Item>
    </Form>
  );
};

export default AddUserForm;
