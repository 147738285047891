// src/Login.js
import React, { useContext, useState } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { AuthContext } from "../../context/Context"; // Adjust the path as necessary
import { Navigate, useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Logo from "../../Img/Tidylogo.png";
import "./Login.css";
import ApiPath from "../../hooks/ApiFetch";
import Cookies from "js-cookie";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogin = async (values) => {
    setLoading(true);
    console.log("values", values);
    try {
      // Make the API request for login
      const response = await ApiPath.post("users/login", {
        ...values,
        email: values.username.toLowerCase(), // Convert email to lowercase for consistency
      });
      // Save the token or relevant data to cookies
      const { token, username: user } = response.data; // Adjust keys based on your API response
      Cookies.set("authToken", token, { expires: 1 }); // Expires in 7 days
      Cookies.set("userInfo", JSON.stringify(user), { expires: 7 });
      // Handle successful response
      message.success("Login successful!");
      setTimeout(() => {
        setLoading(false);
        message.success("Login successful!");
        setIsLoggedIn(true);
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error("Login error:", error);
      message.error("Login failed. Please try again.");
    } finally {
      // Stop loading in all cases
      setLoading(false);
    }
  };
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="login-container">
      <div className="login-left"></div>
      <div className="login-right">
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{ remember: true }}
          onFinish={handleLogin}
        >
          <img
            // src={process.env.PUBLIC_URL + "/user-icon.png"}
            src={Logo}
            alt="User Icon"
            className="user-icon"
          />
          {/* <h2>WELCOME</h2> */}
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item>
            <a className="login-form-forgot" href="/">
              Forgot Password?
            </a>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
