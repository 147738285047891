import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  notification,
  Form,
  Col,
  Row,
  Select,
  Pagination,
  Input,
  message,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import AddCouponForm from "./Add"; // Adjust the path as necessary
import axios from "axios";
import { API_URL } from "../../../hooks/Api";
import fetchApi from "../../../hooks/ApiFetch";
import { AuthContext } from "../../../context/Context";
import moment from "moment";
import ApiPath from "../../../hooks/ApiFetch";

const { Option } = Select;

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(undefined); // Track the coupon being edited
  const [form] = Form.useForm();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchCode, setSearchCode] = useState(""); // Search term for coupon code
  const [searchType, setSearchType] = useState(""); // Search term for coupon type
  const { coupons: data, setcoupons: setData } = useContext(AuthContext);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetchApi.get(`/coupons`);
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch data.");
    }
  };

  const handleAddCoupon = () => {
    setEditingUser(null); // Reset for adding a new user
    form.resetFields(); // Reset form fields
    form.setFieldsValue({ expire: [null, null] }); // Set date range to null
    setIsModalVisible(true);
  };

  const handleEditCoupon = (coupon) => {
    setEditingUser(coupon);
    form.setFieldsValue({ ...coupon });
    form.setFieldsValue({
      ...coupon,
    });
    setUploadedFile(coupon?.code);
    setIsModalVisible(true);
  };

  const handleDeleteCoupon = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this coupon?",
      icon: <DeleteOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          await ApiPath.delete(`/coupons/${id}`);
          setData((prevData) => prevData.filter((item) => item._id !== id));
          notification.success({
            message: "Coupon Deleted",
            description: "Coupon has been deleted successfully.",
          });
        } catch (error) {
          notification.error({
            message: "Operation Failed",
            description: "An error occurred while deleting the coupon.",
          });
        }
      },
    });
  };

  const handleCancel = () => {
    // setEditingUser(null);
    setIsModalVisible(false);
    // form.resetFields(); // Reset form fields when modal is closed
  };

  const handleOk = async (values) => {
    try {
      if (editingUser) {
        // Update existing coupon
        const res = await ApiPath.put(`/coupons/${editingUser._id}`, {
          ...values,
          code: uploadedFile,
        });
        setData((prevData) =>
          prevData.map((item) =>
            item._id === editingUser._id ? { ...item, ...res.data } : item
          )
        );
        notification.success({
          message: "Coupon Updated",
          description: "Coupon has been updated successfully.",
        });
        // window.location.reload();
      } else {
        const updatedValues = { ...values };
        console.log("value", updatedValues);
        if (uploadedFile) {
          updatedValues.code = uploadedFile;
        }
        // Add new coupon
        const response = await ApiPath.post(`/coupons`, {
          ...values,
          code: uploadedFile,
        });
        setData((prevData) => [
          ...prevData,
          { ...updatedValues, _id: response.data._id }, // Use response ID if available
        ]);
        notification.success({
          message: "Coupon Added",
          description: "Coupon has been added successfully.",
        });
      }
      setIsModalVisible(false);
      form.resetFields();
      // window.location.reload();
    } catch (error) {
      notification.error({
        message: "Operation Failed",
        description: "An error occurred while processing your request.",
      });
      console.log("Error", error);
    }
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  // const filteredData = data.filter(
  //   (item) =>
  //     item.code.toLowerCase().includes(searchCode.toLowerCase()) &&
  //     item.type.toLowerCase().includes(searchType.toLowerCase())
  // );
  const filteredData = data.filter((item) => item);

  const columns = [
    {
      title: "#",
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <div>
          Code
          <Input
            placeholder="Search Code"
            value={searchCode}
            onChange={(e) => setSearchCode(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "code",
      key: "code",
    },
    {
      title: (
        <div>
          Type
          <Input
            placeholder="Search Type"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            style={{ marginTop: 8 }}
          />
        </div>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Details",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditCoupon(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteCoupon(record._id)}
            style={{ color: "red" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <Col>
          <h2>Coupons</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAddCoupon}
          >
            Add Coupon
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        footer={() => (
          <Row justify="space-between" align="middle">
            <Col>
              <Select
                defaultValue={pageSize}
                style={{ width: 120 }}
                onChange={handlePageSizeChange}
              >
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
            </Col>
            <Col>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
                showTotal={(total) => `Total ${total} items`}
                total={filteredData.length}
              />
            </Col>
          </Row>
        )}
      />
      <Modal
        title={editingUser ? "Edit Coupon" : "Add Coupon"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <AddCouponForm
          form={form}
          onFinish={handleOk}
          initialValues={editingUser}
          edit={!!editingUser}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      </Modal>
    </div>
  );
};

export default App;
