import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  notification,
  Select,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Parent } from "../../Data/Data";
const { Option } = Select;
const { TextArea } = Input;

const AddCategory = ({
  form,
  onFinish,
  initialValues,
  handleFileChange,
  base64Image,
  loading,
}) => {
  return (
    <Form
      form={form}
      onFinish={(values) => onFinish({ ...values })}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please input the Title!" }]}
      >
        <Input placeholder="Slug" />
      </Form.Item>
      <Form.Item
        name="slug"
        label="Slug"
        rules={[{ required: true, message: "Please input the slug!" }]}
      >
        <Input placeholder="Slug" />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select
          showSearch
          placeholder="Select a category"
          optionFilterProp="children"
          style={{ textTransform: "capitalize" }}
          // onChange={handleCategoryChange}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Option
            key={"active"}
            value={"active"}
            style={{ textTransform: "capitalize" }}
          >
            Active
          </Option>
          <Option
            key={"inactive"}
            value={"inactive"}
            style={{ textTransform: "capitalize" }}
          >
            Inactive
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: "Please input the Description!" }]}
      >
        <TextArea placeholder="Description" />
      </Form.Item>

      <div style={{ display: "flex" }}>
        <img
          src={base64Image ? base64Image : initialValues?.image}
          width="150px"
          style={{ margin: "20px", marginLeft: "0px", borderRadius: 10 }}
        />

        <Form.Item
          name="image"
          label="Image"
          rules={[
            {
              required: true,
              message: "Please Select File!",
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={handleFileChange}
            style={{ width: "300px", background: "green" }}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCategory;
