// src/App.js
import React from "react";
import "./App.css";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import "antd/dist/reset.css"; // Ensure Ant Design styles are included
import Login from "./Pages/Login/Login";
import Users from "./Pages/Dashboard/Users/Users";
import Categories from "./Pages/Dashboard/Categories/Categories";
import Banner from "./Pages/Dashboard/Banners/Banner";
import Coupons from "./Pages/Dashboard/Coupons/Coupons";
import Vendors from "./Pages/Dashboard/Vendors/Vendors";
import Products from "./Pages/Dashboard/Products/Products";
import Orders from "./Pages/Dashboard/Orders/Orders";
import Transactions from "./Pages/Dashboard/Transactions/Transactions";
import PaymentMethods from "./Pages/Dashboard/PaymentMethods/PaymentMethods";
import Faq from "./Pages/Dashboard/Faqs/Faq";
import Support from "./Pages/Dashboard/Support/Support";
import Settings from "./Pages/Dashboard/Settings/Settings";
import DeliveryProfile from "./Pages/Dashboard/DeliveryProfile/DeliveryProfile";
import { AuthProvider } from "./context/Context";
import ProtectedRoutes from "./Pages/ProctectRoute";
import Dashbaord from "./Pages/Dashboard/Dashbaord";
import { ConfigProvider } from "antd";
import ApplicationForm from "./Pages/Dashboard/Application/Application";
import NotFound from "./Pages/Dashboard/404/404";
import Parent from "./Pages/Dashboard/Parent/Parent";
import DriverPage from "./Pages/Dashboard/Driver/Drivers";
import locale from "antd/locale/zh_CN";
import dayjs from "dayjs";
import ContactUs from "./Pages/Dashboard/ContactUs/ContactUs";
import Invoice from "./Invoice/Invoice";
import ProductsTwo from "./Pages/Dashboard/Products copy/Products";

// import "dayjs/locale/zh-cn";

// dayjs.locale("zh-cn");

const { Content } = Layout;

const App = () => {
  return (
    <AuthProvider>
      <ConfigProvider
        // locale={locale}
        theme={{
          token: {
            fontFamily: "Plus Jakarta Sans",
            fontSize: 13,
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/Invoice/:id" element={<Invoice />} />
            <Route path="/" element={<ProtectedRoutes />}>
              <Route index element={<Navigate to="dashboard" />} />{" "}
              <Route path="/users/drivers" element={<Users />} />
              <Route path="dashboard" element={<Dashbaord />} />
              <Route path="/products/categories" element={<Categories />} />
              <Route path="/products/parent" element={<Parent />} />
              <Route path="banners" element={<Banner />} />
              <Route path="coupons" element={<Coupons />} />
              <Route path="deliveryprofile" element={<DriverPage />} />
              <Route path="vendor" element={<Vendors />} />
              <Route path="products/products" element={<Products />} />
              <Route path="products/producttwo" element={<ProductsTwo />} />
              <Route path="orders" element={<Orders />} />
              <Route path="transactions" element={<Transactions />} />
              <Route path="paymentmethods" element={<PaymentMethods />} />
              <Route path="faqs" element={<Faq />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="support" element={<Support />} />
              <Route path="settings" element={<Settings />} />
              <Route path="application" element={<ApplicationForm />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default App;
