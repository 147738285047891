import React, { useContext } from "react";
import { Form, Input, Button, Select, Upload, Card, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/Context";

const { Option } = Select;

const MyForm = ({ form, onFinish, initialValues }) => {
  const { Category, Vendors } = useContext(AuthContext);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      hideRequiredMark
    >
      <Form.Item
        label="First Name"
        name="firstname"
        rules={[{ required: true, message: "First name is required" }]}
      >
        <Input placeholder="Enter first name" />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastname"
        rules={[{ required: true, message: "Last name is required" }]}
      >
        <Input placeholder="Enter last name" />
      </Form.Item>

      {/* Email */}
      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Email is required" },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input placeholder="Enter email" />
      </Form.Item>

      {/* Mobile Number */}
      <Form.Item
        label="Mobile Number"
        name="mobileNumber"
        rules={[
          {
            pattern: /^\d{7,15}$/,
            message: "Please enter a valid mobile number (7-15 digits)",
          },
        ]}
      >
        <Input placeholder="Enter mobile number" />
      </Form.Item>

      {/* Gender */}
      <Form.Item label="Gender" name="gender">
        <Select placeholder="Select gender">
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="other">Other</Option>
        </Select>
      </Form.Item>

      {/* Profile Picture Upload */}
      <Form.Item label="Profile Picture" name="profilepic">
        <Upload name="file" listType="picture" maxCount={1}>
          <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
        </Upload>
      </Form.Item>

      {/* Address */}
      <Form.Item label="Address" name="address">
        <Input.TextArea placeholder="Enter address" rows={3} />
      </Form.Item>

      {/* User Balance */}
      <Form.Item
        label="User Balance"
        name="userBalance"
        rules={[
          {
            type: "number",
            min: 0,
            message: "Balance cannot be negative",
            transform: (value) => (isNaN(value) ? 0 : Number(value)),
          },
        ]}
      >
        <Input type="number" placeholder="Enter balance" />
      </Form.Item>

      {/* Language */}
      <Form.Item label="Language" name="language" initialValue="en">
        <Select>
          <Option value="en">English</Option>
          <Option value="ar">Arabic</Option>
          <Option value="fr">French</Option>
        </Select>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MyForm;
