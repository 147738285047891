import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Tooltip,
  Checkbox,
  message,
  Modal,
} from "antd";
import {
  InfoCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import axios from "axios";

const { TextArea } = Input;
const { Option } = Select;

const MyForm = ({ form, onFinish, initialValues }) => {
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        style={{ maxWidth: 600, margin: "0 auto" }}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Title is required" }]}
        >
          <Input placeholder="Title" />
        </Form.Item>

        <Form.Item
          label={
            <span>
              Slug&nbsp;
              <Tooltip title="Must be unique, all small letters, no spaces. Example - test-cod">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="slug"
          rules={[{ required: true, message: "Slug is required" }]}
        >
          <Input placeholder="Slug" />
        </Form.Item>

        <Form.Item label="Meta" name="meta">
          <TextArea rows={4} placeholder="Meta" />
        </Form.Item>

        <Form.Item name="enabled" valuePropName="checked" initialValue={false}>
          <Checkbox>Enabled?</Checkbox>
        </Form.Item>

        <Form.Item label="Type" name="type">
          <Select placeholder="Select Type">
            <Option value="type1">Type 1</Option>
            <Option value="type2">Type 2</Option>
            <Option value="type3">Type 3</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MyForm;
