import React, { useContext } from "react";
import { Form, Input, Button, Select, Upload, Card, Image } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthContext } from "../../../context/Context";

const { Option } = Select;

const MyForm = ({ form, onFinish, initialValues }) => {
  const { Category, Vendors } = useContext(AuthContext);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialValues}
      layout="vertical"
      hideRequiredMark
    >
      <Card title="Vendor Information">
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: "Email is required" }]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="mobile_number"
          label="Mobile Number"
          rules={[{ required: true, message: "Mobile number is required" }]}
        >
          <Input type="tel" placeholder="Mobile Number" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Password is required" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
      </Card>

      <Card title="Name Translation">
        <Form.Item
          name="name"
          label="Name (English)"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
      </Card>

      <Card title="Tagline Translation">
        <Form.Item
          name="tagline"
          label="Tagline (English)"
          rules={[{ required: true, message: "Tagline is required" }]}
        >
          <Input placeholder="Tagline" />
        </Form.Item>
      </Card>

      <Card title="Details Translation">
        <Form.Item
          name="details"
          label="Details (English)"
          rules={[{ required: true, message: "Details are required" }]}
        >
          <Input placeholder="Details" />
        </Form.Item>
      </Card>

      <Form.Item name="categories" label="Category">
        <Select
          mode="multiple"
          placeholder="Select categories"
          style={{ width: "100%" }}
        >
          {Category.map((a, b) => (
            <Option value={a._id}>{a.title}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="minimum_order"
        label="Minimum Order"
        rules={[{ required: true, message: "Minimum Order is required" }]}
      >
        <Input type="number" placeholder="Minimum Order" />
      </Form.Item>

      <Form.Item
        name="delivery_fee"
        label="Delivery Fee"
        rules={[{ required: true, message: "Delivery Fee is required" }]}
      >
        <Input type="number" placeholder="Delivery Fee" />
      </Form.Item>
      <Form.Item
        label="Commision Type"
        name="CommisionType"
        rules={[{ required: true, message: "Please input your type!" }]}
        style={{ marginBottom: "8px" }}
      >
        <Select placeholder="Select Type">
          <Option value="Percentage">Percentage</Option>
          <Option value="Amount">Amount</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="CommisionUnit"
        label="Commision"
        rules={[{ required: true, message: "Commision Unit is required" }]}
      >
        <Input placeholder="Commission in AED" />
      </Form.Item>
      <Form.Item
        name="area"
        label="Area"
        rules={[{ required: true, message: "Area is required" }]}
      >
        <Input placeholder="Area" />
      </Form.Item>

      <Form.Item
        name="address"
        label="Address"
        rules={[{ required: true, message: "Address is required" }]}
      >
        <Input placeholder="Address" />
      </Form.Item>

      <Form.Item
        name="latitude"
        label="Latitude"
        rules={[{ required: true, message: "Valid latitude is required" }]}
      >
        <Input type="number" placeholder="Latitude" />
      </Form.Item>

      <Form.Item
        name="longitude"
        label="Longitude"
        rules={[{ required: true, message: "Valid longitude is required" }]}
      >
        <Input type="number" placeholder="Longitude" />
      </Form.Item>

      <Form.Item name="image" label="Image">
        <Upload
          showUploadList={false}
          action="/upload" // Specify the upload URL if needed
        >
          <Button icon={<UploadOutlined />}>Upload Image</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
          Save
        </Button>
        <Button type="default">Back</Button>
      </Form.Item>
    </Form>
  );
};

export default MyForm;
