import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Row,
  Col,
  notification,
  Select,
} from "antd";
import {
  PlusOutlined,
  UploadOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Parent } from "../../Data/Data";

const { Option } = Select;

const AddBanner = ({ form, onFinish, initialValues }) => {
  const [metaFields, setMetaFields] = useState(
    initialValues.metaFields || [{ key: "", value: "" }]
  );

  const addMetaField = () => {
    setMetaFields([...metaFields, { key: "", value: "" }]);
  };

  const removeMetaField = (index) => {
    const newMetaFields = metaFields.filter((_, i) => i !== index);
    setMetaFields(newMetaFields);
  };

  const handleMetaFieldChange = (index, field, value) => {
    const newMetaFields = [...metaFields];
    newMetaFields[index][field] = value;
    setMetaFields(newMetaFields);
  };

  return (
    <Form
      form={form}
      onFinish={(values) => onFinish({ ...values, metaFields })}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please input the Title!" }]}
      >
        <Input placeholder="Slug" />
      </Form.Item>

      <Form.Item
        name="sortOrder"
        label="Sort Order"
        rules={[{ required: true, message: "Please input the sort order!" }]}
      >
        <Input placeholder="1" />
      </Form.Item>

      <Form.Item
        name="image"
        label="Image"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload name="logo" action="/upload.do" listType="picture">
          <Button icon={<UploadOutlined />}>Choose File</Button>
        </Upload>
      </Form.Item>

      <Form.Item label="Meta Fields">
        {metaFields.map((metaField, index) => (
          <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
            <Col span={11}>
              <Input
                placeholder="Key"
                value={metaField.key}
                onChange={(e) =>
                  handleMetaFieldChange(index, "key", e.target.value)
                }
              />
            </Col>
            <Col span={11}>
              <Input
                placeholder="Value"
                value={metaField.value}
                onChange={(e) =>
                  handleMetaFieldChange(index, "value", e.target.value)
                }
              />
            </Col>
            <Col span={2}>
              <Button
                type="danger"
                icon={<MinusCircleOutlined />}
                onClick={() => removeMetaField(index)}
              />
            </Col>
          </Row>
        ))}
        <Button type="dashed" onClick={addMetaField} icon={<PlusOutlined />}>
          Add Meta Fields
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddBanner;
